@import "../colors.scss";

.stepTwo-scheduling {
  .ant-btn {
    color: $prev-btn-color;
    padding: 20px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: "Poppins";
    font-size: 16px;
    margin-top: 20px;
  }
  .ant-btn-primary {
    color: $step1-color;
    padding: 20px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: "Poppins";
    font-size: 16px;
    margin-top: 20px;
  }
  .canvas-style {
    display: grid;
    flex: 1;
    // justify-content: center;
    align-items: center;
  }
}

.Canvas-module_container__22cN4.Canvas-module_pannable__1d7xd::-webkit-scrollbar {
  width: 12px;
}

.Canvas-module_container__22cN4.Canvas-module_pannable__1d7xd::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 1px $search-border;
  border-radius: 5px;
  border: 1px solid $scroll-border-color;
}

.Canvas-module_container__22cN4.Canvas-module_pannable__1d7xd::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(23, 194, 23, 0.5);
  background-color: $scroll-bg;
}
