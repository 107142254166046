@import "../colors.scss";

.heatMap-container {
  background-color: $heatMap-bg;
  padding: 30px 25px;
  margin-top: 50px;
  margin-bottom: 20px;
  border-radius: 10px;
  .row-section-heatMap {
    margin-bottom: 50px;
    border-bottom: 1px solid $overall-border-color;
    .machine-name-text {
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 400;
      color: $machine-name-text;
    }
    .heatmap {
      margin-bottom: 50px;
    }
  }
  .row-section-heatMap:last-child {
    margin-bottom: unset;
    border-bottom: unset;
    .heatmap {
      margin-bottom: unset;
    }
  }
}

.ant-picker-clear {
  fill: white !important;
  color: white !important;
}
