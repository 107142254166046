.AverageTable-section {
  /*background-color: #222236;*/
  margin-top: 25px;
  width: 100vw;
  overflow: hidden;
}

.AverageTable-section .filter-section {
  margin: 10px;
}

.AverageTable-section hr {
  border: 1px solid rgba(10, 72, 131, 0.8);
}

.AverageTable-section .table-section {
  margin: 10px;
}
