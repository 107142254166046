@import "../colors.scss";

.content-edit-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  .h2-user-management {
    color: #eceff1;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 24px;
  }

  .content-header-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 20px;
  }

  .add-user-form-container {
    display: flex;
    flex-direction: column;
    // width: 60%;
  }
  .ant-form-item-control-input-content .ant-input {
    height: 45px;
    align-items: center;
    border-radius: 5px;
    // border: 1.5px solid $modal-input-border-color;
    background-color: unset;
    color: $modal-label-color !important;
    font-family: "Poppins";
    font-size: 15px;
    margin-bottom: 10px;
    // border: 1.5px solid $modal-input-border-color;
  }
  .ant-input-affix-wrapper {
    border: 1.5px solid $modal-input-border-color;
    background-color: unset;
  }
  .ant-input-affix-wrapper > input.ant-input {
    color: #898e9e;
    background-color: unset;
    height: 30px;
  }
  .ant-input-number-input-wrap {
    height: 45px;
    align-items: center;
    border-radius: 5px;
    border: 1.5px solid $modal-input-border-color;
    background-color: unset;
    color: $modal-label-color !important;
    font-family: "Poppins";
    font-size: 15px;
  }
  .ant-select-selector {
    height: 45px;
  }
  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: unset;
    border-radius: 5px;
    font-family: "Poppins";
    font-size: 15px;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: unset;
    border-radius: 5px;
    height: 45px;
    font-family: "Poppins";
    font-size: 15px;
    color: $modal-label-color !important;
  }
  .edit-profile-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    button {
      height: 45px;
      // background-color: $primary-color;
      border-radius: 5px;
      border: none;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      font-family: "Poppins";
      color: $search-font;
    }
    .ant-btn-primary {
      // background-color: $primary-color;
      color: $button-font;
      font-family: "Poppins";
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    // border: none;
  }
  .ant-input[disabled] {
    border: 1.5px solid $modal-input-border-color;
  }
  .change-pwd {
    input[type="password"]:-webkit-autofill,
    input[type="password"]:-webkit-autofill:hover,
    input[type="password"]:-webkit-autofill:focus,
    input[type="password"]:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 50px $inputWrapperColor-login inset !important;
      -webkit-text-fill-color: #898e9e !important;
      -webkit-transition: "color 1000000s ease-out, background-color 1000000s ease-out";
      -webkit-transition-delay: 1000000s;
    }
    input[type="confirm"]:-webkit-autofill,
    input[type="confirm"]:-webkit-autofill:hover,
    input[type="confirm"]:-webkit-autofill:focus,
    input[type="confirm"]:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 50px $inputWrapperColor-login inset !important;
      -webkit-text-fill-color: #898e9e !important;
      -webkit-transition: "color 1000000s ease-out, background-color 1000000s ease-out";
      -webkit-transition-delay: 1000000s;
    }
  }
}

.ant-form-item-has-feedback.ant-form-item-has-success
  .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning
  .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error
  .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating
  .ant-form-item-children-icon {
  right: 350px;
}

// .checkPasswordModal {
.confirm-pwd-modal {
  padding: 20px;
  .ant-modal-content {
    border-radius: 5px;
    .ant-form-item-label {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }
  .ant-modal-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    font-family: "Poppins";
    font-size: 15px;
    padding: 20px;
  }
  .ant-input-affix-wrapper {
    color: #898e9e;
    background-color: unset;
    height: 45px;
    width: 100%;
  }
  .ant-input-affix-wrapper .ant-input {
    background-color: unset;
    font-family: "Poppins";
    font-size: 15px;
  }
  .ant-input-affix-wrapper .ant-input::placeholder {
    font-family: "Poppins";
    font-size: 15px;
  }

  .title-edit-profile {
    color: $modal-label-color !important;
    font-family: "Poppins";
    font-size: 15px;
  }
  .edit-profile-btn {
    color: $modal-label-color !important;
    font-family: "Poppins";
    font-size: 15px;
    height: 45px;
    background-color: $primary-color;
    border-radius: 5px;
    border: none;
  }
  .ant-input-number-input-wrap {
    height: 45px;
    align-items: center;
    border-radius: 5px;
    border: 1.5px solid $modal-input-border-color;
    background-color: unset;
    color: $modal-label-color !important;
    font-family: "Poppins";
    font-size: 15px;
  }
  input[type="password"]:-webkit-autofill,
  input[type="password"]:-webkit-autofill:hover,
  input[type="password"]:-webkit-autofill:focus,
  input[type="password"]:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px $inputWrapperColor-login inset !important;
    -webkit-text-fill-color: #898e9e !important;
    -webkit-transition: "color 1000000s ease-out, background-color 1000000s ease-out";
    -webkit-transition-delay: 1000000s;
  }
}
// }
