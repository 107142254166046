@import "../colors.scss";

.machineTableContainer {
  .ant-table {
    background-color: $table-bg;
    border-radius: 10px;
  }
  .ant-table-tbody > tr:last-child td {
    border-bottom-color: transparent;
  }
  .ant-table-tbody > tr > td {
    border-bottom-color: $border-color;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: $row-hover;
  }

  .expand-parent {
    background-color: $row-hover;
  }

  .ant-table-tbody
    > tr:last-child:hover:not(.ant-table-expanded-row)
    > td:first-child {
    border-bottom-left-radius: 10px;
  }
  .ant-table-tbody
    > tr:last-child:hover:not(.ant-table-expanded-row)
    > td:last-child {
    border-bottom-right-radius: 10px;
  }
  .ant-table-thead > tr > th:first-child {
    padding-left: 30px;
  }
  .ant-table-tbody > tr > td:first-child {
    padding-left: 30px;
  }
  .ant-table-thead > tr > th {
    background-color: $thead-color;
    padding-top: 20px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }
  .ant-table-container table > thead > tr > .ant-table-row-expand-icon-cell {
    width: 240px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }
  .ant-table-thead > tr > th {
    border-bottom-color: $thead-border-color;
  }
  .ant-table-thead {
    .ant-table-cell {
      color: $thead-font-color;
      font-family: "Poppins";
      text-align: left;
      font-weight: 600;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      color: $thead-font-color;
      font-family: "Poppins";
      text-align: left;
    }
    .ant-table-cell:first-child {
      color: $tbody-fontMachine-color;
      font-family: "Poppins";
      text-align: left;
      font-weight: 600;
    }
    .ant-table-cell:last-child {
      width: 140px;
      justify-content: center;
    }
  }
  .machineLocation,
  .utilization,
  .iotDevice {
    color: $tbody-location-color;
    font-weight: 300;
    font-size: 13px;
  }
  .viewMore {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    text-decoration: none;
  }
  .viewMore:hover {
    color: unset;
  }

  .ant-pagination .ant-pagination-item {
    background-color: unset;
    border-color: $pagination-border;
  }
  .ant-pagination .ant-pagination-item:hover {
    border-color: $primary-color;
    font-weight: 800;
  }
  .ant-pagination-item a {
    color: $progressText;
  }
  .ant-pagination .ant-pagination-item-active {
    border-color: $primary-color;
    font-weight: 800;
    a {
      color: $primary-color;
    }
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: 0.5px solid $pagination-border;
    color: $progressText;
    background-color: unset;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $pagination-border;
  }
  .ant-progress-text {
    color: $progressText;
    font-size: 15px;
    font-weight: 500;
  }
  .ant-table-expanded-row {
    .ant-table-cell {
      background-color: $table-bg;
      height: 200px;
      padding: 0;
      margin: 0;
    }
  }
  .spinner {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .paragraph {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: $thead-font-color;
      font-weight: 400;
    }
  }
  .expandedDetails {
    display: flex;
    // flex: 1;
    // flex-direction: row;
    // justify-content: space-between;
    // width: 100px;
    margin-left: 200px;

    .utilizationProgress {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      margin: 0 70px 0 0;
      width: 150px;
      font-size: 15px;
    }

    .details1 {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      margin-top: 10px;
      // align-items: center;
      .details2 {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        font-weight: 300;
        margin-right: 30px;
        font-size: 14px;
        align-items: center;
        width: 160px;
      }
      span:first-child {
        // margin-right: 100px;
      }
    }

    .details-container-expanded {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      .util-detail-header {
        margin-bottom: 15px;
        display: flex;
        // flex: 1;
        flex-direction: row;
        .utilizationDetails {
          font-weight: 600;
          font-size: 20px;
          // width: 600px;
        }
      }
      .ant-row {
        // width: 515px !important;
      }
    }
  }

  .ant-input-affix-wrapper {
    background-color: unset;
    border: 1px solid $search-border;
  }
  .ant-input-affix-wrapper:hover {
    border: 1px solid $search-border;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px $shadow-border;
  }
  .ant-input-affix-wrapper > input.ant-input {
    background-color: unset;
    font-weight: 300;
    font-size: 13px;
    color: $tbody-location-color;
    font-family: "Poppins";
  }
  .ant-input::placeholder {
    color: $tbody-location-color;
    font-family: "Poppins";
    font-weight: 300;
    font-size: 13px;
  }
  .ant-select-dropdown {
    background-color: $bg-color-secondary;
    box-shadow: 0 0 0 1.5px rgba(91, 91, 91, 0.5),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
  .ant-select-item-option-content {
    color: $text-color;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
  }
  .ant-select-arrow {
    color: $text-color;
  }
  .ant-select-selection-item {
    color: $text-color;
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 400;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: $hover-dropdown-color;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $hover-dropdown-color;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
    background-color: $hover-dropdown-color;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: unset;
  }
  .h2-title-iot span {
    font-size: 21px;
    font-family: "Poppins";
    font-weight: 600;
    color: $header-font-color;
    text-decoration: none;
  }
  .search-filter-iot {
    display: flex;
    flex: 0.3;
    flex-direction: row;
    align-items: center;
  }
}

.table-filter-dropdown {
  top: 10px;
  right: 30px;
  background-color: $bg-color-secondary;
  width: 150px;
  border-radius: 5px;
  padding: 10px 0 10px 0;
  //border color
  box-shadow: 0 0 0 1.5px rgba(91, 91, 91, 0.5),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  .table-filter {
    color: $text-color;
    line-height: 25px;
    font-family: "Poppins";
  }
}

.filter-modal {
  .ant-modal-content {
    background-color: unset;
    .ant-modal-close-x .anticon {
      color: $tbody-font-color;
    }
  }
  .ant-modal-body {
    background-color: $bg-color-secondary;
    border-bottom: 0.1px solid $thead-color;
  }
  .ant-modal-header {
    background-color: $thead-color;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 0px;
    .ant-modal-title {
      font-family: "Poppins";
      color: $tbody-font-color;
    }
  }
  .ant-modal-footer {
    background-color: $bg-color-secondary;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 0px;
    .ant-btn {
      span {
        font-family: "Poppins";
        color: $tbody-font-color;
      }
    }
    .ant-btn:first-child {
      background-color: unset;
      border: none;
      color: #ffffff;
    }
    .ant-btn:last-child {
      border: none;
      color: #ffffff;
      width: 70px;
    }
  }

  .filters {
    display: flex;
    flex: 1;
    flex-direction: row;
    .machineStatus {
      margin-right: 60px;
      margin-left: 10px;
      span {
        font-family: "Poppins";
        color: $tbody-location-color;
      }
    }
    .iotDevices {
      span {
        font-family: "Poppins";
        color: $tbody-location-color;
      }
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color;
    border-color: $primary-color;
  }
  .ant-checkbox-inner {
    background-color: unset;
    border-color: $thead-font-color;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: unset;
  }
  .ant-checkbox-wrapper {
    margin-top: 15px;
    span {
      font-family: "Poppins";
    }
  }
  .ant-checkbox-group-item {
    margin-top: 8px;
  }
  .ant-btn.ant-btn-loading::before {
    background-color: $primary-color;
  }
  .reset-Filter {
    margin-left: 30px;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-end;
    span {
      font-family: "Poppins";
      color: $thead-font-color;
      font-weight: 300;
      font-size: 14px;
      cursor: pointer;
    }
    span:hover {
      color: $tbody-font-color;
    }
  }
}

.viewMore-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

// search section
.search-section-iot_table {
  margin-top: 20px;
  margin-bottom: 40px;
}

.search-section-iot_table {
  .ant-select-multiple .ant-select-selection-item {
    background-color: rgba(91, 91, 91, 0.5);
    color: #ffffff;
    border: 1px solid rgba(91, 91, 91, 0.5);
  }

  .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
  }

  .h2-title-iot span {
    font-size: 21px;
    font-family: "Poppins";
    font-weight: 600;
    color: $header-font-color;
    text-decoration: none;
  }

  .select-color option {
    display: inline-block;
    height: 40px;
    float: left;
  }
}
