.general-modal-popup
  .ant-modal-content
  .ant-modal-body
  .ant-divider-inner-text {
  justify-content: center !important;
}
.general-modal-popup .ant-modal-content .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-start;
}

.container_view_div {
  border: 1px solid gold; 
}
