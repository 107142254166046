@import "../colors.scss";

.utilProgress {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 230px;
  // align-items: center;
  justify-content: space-evenly;
  // padding-left: 40px;
  // padding-right: 40px;
  .machine-details-section {
    // margin-left: 30px;
    height: 230px;
    border-radius: 10px;
    background-color: #242424;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 35px;
    .machineDetails {
      font-family: "Poppins";
      color: $utilizationName;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 8px;
    }
    .attr-val-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      width: 300px;
      color: $machineName;
      margin-bottom: 10px;
      .machine-details-attr {
        float: left;
        // width: 250px;
        color: $machineName;
        font-family: "Poppins";
        font-size: 15px;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 15px;
      }

      .machine-details-val {
        float: left;
        width: 150px;
        color: $values;
        font-family: "Poppins";
        font-size: 15px;
      }
      .machine-status-tag {
        font-family: "Poppins";
        font-size: 15px;
        float: left;
        width: 150px;
        .ant-tag {
          // color: $tbody-font-color;
          font-family: "Poppins";
          border: none;
          font-size: 15px;
        }
      }
    }
  }

  .utilization-progress {
    background-color: #242424;
    padding: 15px;
    width: 30%;
    height: 230px;
    display: flex;
    // flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    .utilName {
      font-family: "Poppins";
      font-size: 18px;
      margin-bottom: 10px;
      color: $utilizationName;
      font-weight: 500;
    }
    .ant-progress-text {
      color: $progressText;
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 500;
    }
  }
}
