@import "../colors.scss";

.main-container-rtm {
  background-color: $backgroundColor-rtm;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-container-rtm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  height: 250px;
}

.form-footer-container-rtm .submit-rtm {
  margin-top: 10px;
  width: 100%;
}

.submit-btn-rtm:hover,
.submit-btn-rtm:focus {
  background-color: $headerColor-rtm;
}

.login-rtm {
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  width: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  .navigation-rtm {
    margin-left: 40px;
    color: $labelColor-rtm;
    opacity: 0.5;
    font-size: 13px;
    font-family: "Roboto";
  }
}

@media (max-width: 400px) {
  .form-container-rtm {
    width: 85%;
  }
}

span a {
  font-family: "Poppins";
  font-size: 13px;
  color: $labelColor-rtm;
}

.form-header-rtm {
  height: 200px;
  background-color: $headerColor-rtm;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-top: 15px;
}
.form-footer-rtm {
  height: 380px;
  background-color: $footerColor-rtm;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.header-container-rtm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .icons {
    margin-right: 20px;
  }
}

.detail-container-rtm {
  display: flex;
  flex-direction: column;
  width: 220px;
}

.h2-rtm {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $headerFontColor-rtm;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 0;
}

.h4-rtm {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: $headerFontColor-rtm;
  padding-left: 20px;
}

.form-footer-container-rtm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  margin-right: 35px;
  margin-left: 35px;
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: $inputWrapperColor-rtm;
    border-color: #ff4d4f;
  }
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input-focused,
  .ant-input:focus {
    border-color: $headerColor-rtm;
    box-shadow: none;
  }
  /* Change the white to any color */
  input[name="phone"]:-webkit-autofill,
  input[name="phone"]:-webkit-autofill:hover,
  input[name="phone"]:-webkit-autofill:focus,
  input[name="phone"]:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px $inputWrapperColor-rtm inset !important;
    -webkit-text-fill-color: $inputTextColor-rtm !important;
    -webkit-transition: "color 1000000s ease-out, background-color 1000000s ease-out";
    -webkit-transition-delay: 1000000s;
  }

  .react-tel-input .form-control {
    width: 100%;
    background-color: $inputWrapperColor-rtm;
    color: $labelColor-rtm;
    border: 0px;
    border-color: transparent;
  }
  .react-tel-input .flag-dropdown {
    background-color: $inputWrapperColor-rtm;
    border: 0px;
    border-color: transparent;
    color: #fff;
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    background-color: $inputWrapperColor-rtm;
    border: 0px;
    color: #fff;
  }
  .react-tel-input .flag-dropdown:hover {
    background-color: $highlight;
  }
  .react-tel-input .country-list,
  .react-tel-input .country-list .search {
    background-color: $inputWrapperColor-rtm;
    box-shadow: none;
    width: 280px;
  }
  .react-tel-input .country-list .search-box {
    background-color: transparent;
    width: 80%;
    border-width: 0.01rem;
    border-color: $labelColor-rtm;
    opacity: 0.5;
  }
  .react-tel-input .country-list .country.highlight {
    background-color: $highlight;
  }
  .react-tel-input .country-list .country.highlight:hover {
    background-color: $highlight;
  }
  .react-tel-input .country-list .country:hover,
  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background-color: $highlight;
  }
  .form-footer-container-rtm .react-tel-input .country-list .search-box {
    margin-bottom: 10px;
  }
}

.ant-form-item-label > label {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: $labelColor-rtm !important;
}

.ant-input-affix-wrapper {
  background-color: $inputWrapperColor-rtm;
  border-width: 1px;
  border-color: $inputWrapperColor-rtm;
  border-radius: 4px;
  width: 270px;
  height: 40px;
}
.ant-input::placeholder {
  color: #d4d4d4;
  opacity: 0.35;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
}

.ant-input::placeholder {
  font-size: "Roboto";
  font-size: 20;
  font-weight: 600;
}

.form-footer-container-rtm button {
  width: 100%;
  background-color: $headerColor-rtm;
  border: none;
  font-family: "Roboto";
  opacity: 0.8;
}

.ant-input-affix-wrapper > input.ant-input {
  color: #898e9e;
}

.powered-by-rtm {
  margin-top: 25px;
  color: #898e9e;
  opacity: 0.5;
}
