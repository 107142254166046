@import "./colors.scss";

.hide-x-scroll {
  overflow-x: hidden;
}

.modal-popup-header {
  .ant-modal-header {
    display: flex;
    justify-content: center;
  }
}

.general-modal-popup {
  .ant-modal-content {
    border-radius: 10px;
    width: 70%;
    .ant-modal-header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 25px;
      border-bottom: 2px solid $modal-border-color;
      .ant-modal-title {
        color: $header-modal-title;
        font-family: "Poppins";
        font-size: 20px;
      }
    }
    .ant-modal-body {
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 0px;
      align-items: center;
      margin-bottom: 15px;
      .ant-form-item-label > label {
        color: $modal-label-color !important;
        font-family: "Poppins";
        font-size: 15px;
      }
      .ant-form-item-control-input-content {
        .ant-btn-dashed {
          background-color: $add-machine-button;
          height: 45px;
          border-radius: 5px;
          color: $text-color-des;
          border: none;
          font-family: "Poppins";
          font-size: 15px;
        }
      }
      .ant-divider-inner-text {
        color: $text-color-des;
        font-family: "Poppins";
        font-size: 15px;
        justify-content: space-between;
        display: flex;
        width: 100%;
      }
      .ant-divider
        .ant-divider-horizontal
        .ant-divider-with-text
        .ant-divider-with-text-center {
        margin-bottom: 30px;
      }
      .ant-divider-horizontal.ant-divider-with-text {
        border-top-color: $divider-color;
      }
      .ant-row .ant-form-item .display {
        width: 150% !important;
      }
    }
    .ant-modal-footer {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      // margin-top: 10px;
      button {
        height: 45px;
        // background-color: $primary-color;
        border-radius: 5px;
        border: none;
        font-family: "Poppins";
      }
      .ant-btn-primary[disabled],
      .ant-btn-primary[disabled]:hover,
      .ant-btn-primary[disabled]:focus,
      .ant-btn-primary[disabled]:active {
        font-family: "Poppins";
        color: $search-font;
      }
      .ant-btn-primary {
        // background-color: $primary-color;
        color: $button-font;
        font-family: "Poppins";
      }
      .ant-btn:not(.ant-btn-primary) {
        background-color: $cancel-btn-bg;
        color: $button-font;
      }
    }
    .ant-input::placeholder {
      color: $modal-label-color !important;
      font-family: "Poppins";
      font-size: 15px;
      font-weight: 300;
    }
    .ant-select-selection-placeholder {
      color: $modal-selection-color !important;
      font-family: "Poppins";
      font-size: 15px;
      font-weight: 300;
    }
    .ant-input-number {
      background-color: unset;
      border: unset;
    }
    .ant-input-number-input {
      height: 45px;
      background-color: unset;
    }
    .ant-input-number-input::placeholder {
      color: "red !important";
    }
    .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-error .ant-picker:not([disabled]):hover {
      background-color: unset;
    }
    .ant-form-item-control-input-content .ant-input {
      height: 45px;
      align-items: center;
      border-radius: 5px;
      border: 1.5px solid $modal-input-border-color;
      background-color: unset;
      color: $modal-label-color !important;
      font-family: "Poppins";
      font-size: 15px;
      margin-bottom: 10px;
    }
    .ant-input-number-input-wrap {
      height: 45px;
      align-items: center;
      border-radius: 5px;
      border: 1.5px solid $modal-input-border-color;
      background-color: unset;
      color: $modal-label-color !important;
      font-family: "Poppins";
      font-size: 15px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 45px;
      align-items: center;
      border-radius: 5px;
      color: $modal-label-color !important;
      font-family: "Poppins";
      font-size: 15px;
      margin-bottom: 10px;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: unset;
    }
    .ant-checkbox-wrapper {
      span {
        color: $text-color-des;
        font-size: 14px;
        font-family: "Poppins";
      }
    }
    .ant-checkbox-inner {
      background-color: unset;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $checkbox-bg;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border: 1px solid $checkbox-bg;
    }
    .modal-field-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 10px;
      button {
        height: 45px;
        // background-color: $primary-color;
        border-radius: 5px;
        border: none;
      }
      .ant-btn-primary[disabled],
      .ant-btn-primary[disabled]:hover,
      .ant-btn-primary[disabled]:focus,
      .ant-btn-primary[disabled]:active {
        font-family: "Poppins";
        color: $search-font;
      }
      .ant-btn-primary {
        // background-color: $primary-color;
        color: $button-font;
        font-family: "Poppins";
      }
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }
    .ant-form-item-has-error
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: unset;
    }

    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker,
    .ant-form-item-has-error .ant-input {
      border-color: $error-border;
    }
    .ant-typography {
      color: $modal-label-color;
      font-family: "Poppins";
      font-size: 15px;
    }
    .ant-input-group-addon {
      border: none;
      background-color: unset;
      color: $modal-label-color;
      font-family: "Poppins";
      font-size: 20px;
      align-items: center;
    }
  }
  .form-field-plant-multiple {
    .ant-select-selection-placeholder {
      font-family: "Poppins";
    }
    .ant-select-selection-item {
      background-color: $selected-bg;
      color: $selected-color;
      border: none;
      font-family: "Poppins";
      font-size: 13px;
    }
    .anticon-close {
      color: $selected-color;
    }
  }
  .title-calender-label,
  .date-calender-label {
    font-family: "Poppins";
    color: $calender-txt-color;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .title-calender,
  .date-calender {
    font-family: "Poppins";
    color: $search-font;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .btn-calender-container {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: 10px;
      font-family: "Poppins";
      color: $calender-txt-color;
    }
  }
}
.ant-modal-centered .ant-modal {
  display: inline-flex;
  justify-content: center;
}

.ant-modal-footer {
  border-top: none;
}
.ant-input-number-handler-down-inner,
.ant-input-number-handler-up-inner {
  color: rgb(0, 0, 0);
  font-weight: 800;
}

.ant-advanced-search-form {
  .display-action {
    .ant-select-selection-item {
      background-color: $selected-bg;
      color: $selected-color;
      border: none;
      font-family: "Poppins";
      font-size: 13px;
    }
    .anticon-close {
      color: $selected-color;
    }
  }
}
