@import "../colors.scss";

.main-container-rte {
  background-color: $backgroundColor-rte;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-container-rte {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  height: 320px;
}

.submit-btn-rte:hover,
.submit-btn-rte:focus {
  background-color: $headerColor-rte;
}

.login-rte {
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  width: auto;
  margin-bottom: 15px;
  .navigation-rte {
    margin-left: 40px;
    color: $labelColor-rte;
    opacity: 0.5;
    font-size: 13px;
    font-family: "Roboto";
  }
}

@media (max-width: 400px) {
  .form-container-rte {
    width: 85%;
  }
}

span a {
  font-family: "Poppins";
  font-size: 13px;
  color: $labelColor-rte;
}

.form-header-rte {
  height: 200px;
  background-color: $headerColor-rte;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-top: 15px;
}
.form-footer-rte {
  height: 380px;
  background-color: $footerColor-rte;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.header-container-rte {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .icons {
    margin-right: 20px;
  }
}

.detail-container-rte {
  display: flex;
  flex-direction: column;
  width: 220px;
}

.h2-rte {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $headerFontColor-rte;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 0;
}

.h4-rte {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 23px;
  color: $headerFontColor-rte;
  padding-left: 20px;
}

.form-footer-container-rte {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-right: 35px;
  margin-left: 35px;
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: $inputWrapperColor-rte;
    border-color: #ff4d4f;
  }
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input-focused,
  .ant-input:focus {
    border-color: $headerColor-rte;
    box-shadow: none;
  }
  /* Change the white to any color */
  input[type="email"]:-webkit-autofill,
  input[type="email"]:-webkit-autofill:hover,
  input[type="email"]:-webkit-autofill:focus,
  input[type="email"]:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px $inputWrapperColor-rte inset !important;
    -webkit-text-fill-color: $inputTextColor-rte !important;
    -webkit-transition: "color 1000000s ease-out, background-color 1000000s ease-out";
    -webkit-transition-delay: 1000000s;
  }
}

.ant-form-item-label > label {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: $labelColor-rte !important;
}

.ant-input-affix-wrapper {
  background-color: $inputWrapperColor-rte;
  border-width: 1px;
  border-color: $inputWrapperColor-rte;
  border-radius: 4px;
  width: 270px;
  height: 40px;
}
.ant-input::placeholder {
  color: #d4d4d4;
  opacity: 0.35;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
}

.ant-input::placeholder {
  font-size: "Roboto";
  font-size: 20;
  font-weight: 600;
}

.form-footer-container-rte button {
  width: 100%;
  background-color: $headerColor-rte;
  border: none;
  font-family: "Roboto";
  opacity: 0.8;
}

.ant-input-affix-wrapper > input.ant-input {
  color: #898e9e;
}

.powered-by-rte {
  margin-top: 15px;
  color: #898e9e;
  opacity: 0.5;
}
