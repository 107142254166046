@import "../colors.scss";

.userManagementContainer {
  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-layout-content {
    background: #222736;
  }

  .usertable-container button:hover,
  .usertable-container button:focus,
  .usertable-container button {
    background-color: #2856da;
  }

  .add-user-btn {
    width: 95px;
    /* background-color:#218838; */
    margin-left: 20px;
    margin-top: 20px;
    /* font-weight: 500; */
    height: 40px;
    font-size: 13px;
    border-radius: 5px;
    padding: 0px 7px 0px 2px;
  }

  .ant-popover-inner-content {
    padding: 5px;
    background-color: #37474f;
  }

  .ant-popover-buttons button {
    font-size: 10px;
    width: 40px;
    margin: 2px;
    background-color: #009be5;
  }

  .anticon-exclamation-circle {
    display: none;
  }

  .ant-popover-message-title {
    padding-left: 2px;
    font-size: 12px;
  }

  // .user-content {
  //   /* background-color: blue; */
  //   /* margin-top: 20px; */
  //   min-height: 100vh;
  //   display: flex;
  //   justify-content: center;
  //   /* align-items: center; */
  //   margin-top: 20px;
  // }

  // .add-user-form-header {
  //   height: 100px;
  //   background-color: #3b4b71;
  //   border-top-left-radius: 6px;
  //   border-top-right-radius: 6px;
  // }
  // .add-user-form-footer {
  //   /* height: 880px; */
  //   background-color: #2a3042;
  //   border-bottom-left-radius: 6px;
  //   border-bottom-right-radius: 6px;
  //   padding-bottom: 30px;
  //   padding-top: -60px;
  // }

  .avatar-container {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
  }

  // .add-user-form-footer-container {
  //   display: flex;
  //   flex-direction: column;
  //   margin-top: 20px;
  //   margin-right: 35px;
  //   margin-left: 35px;
  // }

  .ant-form-item-label > label {
    color: #bfc3ca;
    font-weight: 400;
  }

  /* .ant-select-selector{
    background-color: white;
} */
  .ant-select-single:not(.ant-select-customize-input) {
    background-color: #2e3548;
  }

  .ant-select-selection-item {
    color: #898e9e;
    font-weight: 500;
    margin-left: 5px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover,
  .ant-menu-item-selected:focus,
  .ant-menu-item:hover,
  .ant-menu-item:focus {
    left: 0;
  }

  /* .ant-menu-item{
    transition: none;
} */
  /* .ant-select-selection-search-input{
    background-color: tomato;
} */
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background: #222736;
  }
  ::-webkit-scrollbar {
    height: 5px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #898e9e;
    border-radius: 5px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #898e9e;
    border-radius: 5px;
  }

  // .add-user-form-footer-container button {
  //   width: 100%;
  //   border: none;
  // }

  // .add-user-form button {
  //   width: 100%;
  //   border: none;
  // }

  // .add-user-btnbutton {
  //   width: 100%;
  //   border: none;
  // }
  .user-search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  // .searchDivider {
  //   margin-right: 40px;
  //   margin-left: 40px;
  //   border-color: rgba(215, 212, 212, 0.57);
  //   height: 26px;
  // }

  .ant-btn-primary {
    background-color: $primary-color;
    display: flex;
    flex: 1;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    font-family: "Poppins";
    border-radius: 5px;
    padding: 20px;
    // margin-left: 21px;
    color: $text-color-user;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    background-color: $button-disabled;
    color: $disabled-font;
    border: 0px;
  }
  .ant-pagination .ant-pagination-item {
    background-color: unset;
    border-color: $pagination-border;
  }
  .ant-pagination .ant-pagination-item:hover {
    border-color: $primary-color;
    font-weight: 800;
  }
  .ant-pagination-item a {
    color: $progressText;
  }
  .ant-pagination .ant-pagination-item-active {
    border-color: $primary-color;
    font-weight: 800;
    a {
      color: $primary-color;
    }
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: 0.5px solid $pagination-border;
    color: $progressText;
  }
  .general-table .ant-pagination-prev .ant-pagination-item-link,
  .general-table .ant-pagination-next .ant-pagination-item-link {
    background-color: unset;
  }
  .user-delete-btn {
    background-color: unset;
    border: none;
    color: $delete-btn;
    padding: 20px;
  }
  .user-edit-btn {
    background-color: unset;
    border: none;
    color: $edit-btn;
    padding: 0px;
  }
  .ant-btn-primary {
    box-shadow: unset;
  }
}
