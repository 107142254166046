@import "../../pages//colors.scss";

.side-nav-mainContainer {
  position: sticky;
  top: 0;
  .header-xep {
    justify-content: center;
    padding: 20px 20px 2px 20px;
  }
  .heading {
    font-size: 25px;
    font-family: "Poppins";
    font-weight: 800;
    color: $header-font-color;
    text-decoration: none;
  }
  .side-navigation-container {
    background-color: $bg-color;
    .navigation-items {
      color: $side-nav-font;
      font-family: "Barlow";
      font-weight: 500;
      font-size: 15px;
    }
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px;
  }
  .ant-menu .ant-menu-item-selected.customclass {
    background-color: $primary-color; /*Overriden property*/
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ant-menu .ant-menu-item:hover {
    background-color: $primary-color;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ant-menu-submenu-title:hover {
    background-color: $primary-color;
    color: $side-nav-font;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .ant-menu-title-content,
  .ant-menu-submenu-arrow {
    color: $side-nav-font;
    font-family: "Barlow";
    font-weight: 500;
    font-size: 15px;
  }

  .ant-menu-submenu-arrow:hover {
    color: $side-nav-font;
  }

  .ant-menu .ant-menu-sub {
    background-color: $bg-color-secondary;
    padding: 0px;
    margin: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    color: $side-nav-font;
  }
}

.side-container {
  background-color: $bg-color;
  // margin-top: 400px;
  // position: fixed;
}
