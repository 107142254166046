@import "../colors.scss";
.radio-group-style {
  // background-color: red;
  flex: 1;
  justify-content: space-around;
  .ant-radio-wrapper {
    color: $radio-btn;
    font-size: 16px;
    font-family: "Poppins";
    margin-right: 50px;
  }
}
