@import "../colors.scss";

.general-card {
  background-color: $table-bg;
  border-radius: 12px;
}

.cart-left {
  color: #ffffff;
  position: relative;
  width: 550px;
  //   height: 200px;
  font-family: "Poppins";
  font-size: 14px;
  text-align: left;
}

.cart-right {
  color: #ffffff;
  position: absolute;
  top: 25px;
  right: 120px;
  width: 250px;
  height: 190px;
  font-family: "Poppins";
  font-size: 14px;
}

.maintenance-title {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 100px;
}

.label-text {
  font-family: "Poppins";
  font-size: 14px;
  color: #ffffff;
  top: -15px;
  //   display: flex;
  text-align: left;
}

.card-align {
  .ant-form-item-label > label {
    display: flex;
    justify-content: space-between;
    flex: auto;
  }
}

.label-text-1 {
  padding-right: 25px;
  .ant-form-item-label {
    text-align: right;
  }
}

.statusColor {
  font-family: "Poppins";
  display: "inline-flex";
  flex-direction: "row";
  justify-content: "center";
  align-items: "center";
  margin-bottom: "5px";
  font-weight: 600;
}
