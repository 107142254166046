@import "../colors.scss";

.inner-dark-section {
  .ant-picker-clear {
    background-color: unset;
    color: $close-icon !important;
    background-color: $close-icon-bg;
    height: 15px;
    width: 15px;
    justify-content: center;
    align-items: center;
    flex: 1;
    display: flex;
  }
}
