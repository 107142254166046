@import "../colors.scss";

.h2-cable-production {
  color: #eceff1;
  padding: 8px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 24px;
}

.cable-production-form-container {
  background-color: #222230;
  width: 1050px;
  padding: 25px;
  /* height: 100vh; */
}

// .cable-section {
//   /*margin: 0% 0 2% 0;*/
//   /*padding: 0% 0 0 0.5%;*/
//   background-color: transparent; /* #222230 */
//   position: relative;
//   display: flex;
//   flex-direction: row;
//   min-height: 500px;
//   // border-bottom: 1px solid #177ddc;
//   margin-left: -20px;
// }

.closest {
  stroke: yellow !important;
}
.dragger {
  z-index: 999;
  pointer-events: none;
  user-select: none;
  cursor: grabbing;
  height: 70px;
  width: 150px;
}
.dragInner {
  pointer-events: none;
  margin-left: 80px;
  border-radius: 2px;
  border: solid 1px #00c5be;
  height: 30px;
  width: 70px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e3548;
}
.node {
  background: #272626 !important;
  height: 50px;
  border-right: 30px;
}
.edge {
  stroke: #b1b1b7;
  /*stroke-dasharray: 5;*/
  /*animation: dashdraw .5s linear infinite;*/
  /*stroke-width: 1;*/
}

.button {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  margin-left: 18px;
}
.node-custom {
  padding: 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
}
.popup-img {
  height: 20px;
  cursor: pointer;
  background: #00fff5;
  border-radius: 3px;
  margin-right: 5px;
}
.popup-container {
  height: 20px;
  cursor: pointer;
  background: #5de23b;
  border-radius: 3px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
}
.drag-icon {
  margin-right: 10px;
}
.add-button {
  border-radius: 5px;
  //   margin-left: 18px;
}
// .pallet-title {
//   margin-bottom: -12px;
//   width: 130px;
//   font-size: 17px;
// }
.form-row {
  justify-content: space-between;
}
.time-picker {
  width: 100%;
}
.validate-icon {
  right: 0;
  top: -4px;
  position: absolute;
}

.user-delete-btn {
  background-color: unset;
  border: none;
  color: $delete-btn;
  padding: 20px;
}
.user-edit-btn {
  background-color: unset;
  border: none;
  color: $edit-btn;
  padding: 0px;
}

.ant-pagination .ant-pagination-item {
  background-color: unset;
  border-color: $pagination-border;
}
.ant-pagination .ant-pagination-item:hover {
  border-color: $primary-color;
  font-weight: 800;
}
.ant-pagination-item a {
  color: $progressText;
}
.ant-pagination .ant-pagination-item-active {
  border-color: $primary-color;
  font-weight: 800;
  a {
    color: $primary-color;
  }
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 0.5px solid $pagination-border;
  color: $progressText;
  background-color: unset;
}
.ant-select-selector {
  color: $progressText;
  font-family: "Poppins";
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid $pagination-border;
  align-items: center;
}
.ant-pagination-options-quick-jumper input {
  background-color: unset;
  border: 1px solid $pagination-border;
  color: $progressText;
  font-family: "Poppins";
}
.ant-pagination-options-quick-jumper {
  color: $progressText;
  font-family: "Poppins";
}
.ant-pagination-item-container {
  .ant-pagination-item-ellipsis {
    color: $progressText;
  }
  .ant-pagination-item-link-icon {
    color: $progressText;
  }
}

.general-table .ant-pagination-prev .ant-pagination-item-link,
.general-table .ant-pagination-next .ant-pagination-item-link {
  background-color: unset;
}

// search and filter section
.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: flex;
  align-items: center;
  width: 100%;
}

.width-70per {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
}

.width-30per {
  width: 40%;
}

.search_menu_payment_icon {
  a {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #cacaca !important;
  }
}
