@import "../colors.scss";

.cable-process {
  .reactflow-wrapper {
    width: 1000px;
    height: 450px;
    padding-bottom: 15px;
  }

  .back-btn {
    margin-bottom: 20px;
  }

  .save-btn {
    border-radius: 5px;
  }

  .save-as-button {
    padding-top: 15px;
  }
  .task-container {
    width: 50%;
    background-color: $process-bg;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
    .pallet-title {
      color: $process-title;
      font-family: "Poppins";
      width: 100%;
      font-size: 20px;
      margin-bottom: 15px;
    }
    .drag-fields {
      background-color: unset;
      border: none;
      display: flex;
      flex-direction: row;
      width: 100%;
      color: $process-title;
      font-family: "Poppins";
      justify-content: space-between;
    }
  }
  // .left {
  //   flex-direction: row;
  //   display: flex;
  //   position: absolute;
  //   justify-content: space-around;
  //   bottom: 0;
  //   top: 0;
  //   left: 0;
  //   width: 650px;
  //   background: rgba(0, 0, 0, 0.4);
  //   padding: 13px;
  //   margin-bottom: 5px;
  //   align-items: center;
  //   height: 105px;
  //   border-style: solid;
  //   border-color: #14ffec;
  //   border-width: 1px;
  // }
  .button-box {
    display: flex;
    justify-content: flex-end;
    // height: 110px;
    align-items: center;
    right: 0px;
    // margin-left: 25%;
    // position: relative;
  }
  .progress-convas {
    position: absolute;
    height: 70%;
    width: calc(100% - 335px);
    background: $process-bg;
    margin-top: 20px;
    border-radius: 10px;
  }
  .load-right {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0;
    height: 100%;
    background: #2a3042;
  }
  .block {
    height: 50px;
    max-width: 140px;
    cursor: grab;
    background: #2e3548;
    *margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex: 1;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 5px;
  }
  .Canvas-module_container__22cN4.Canvas-module_pannable__1d7xd {
    overflow: overlay;
  }
  .node-label {
    width: 100%;
    margin-top: 0px;
  }
  .node-custom {
    color: $process-title;
    font-family: "Poppins";
    padding: 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .node-custom .node_icon {
    display: flex;
    flex-direction: row-reverse;
    width: 35%;
  }
  .node_label_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 65%;
  }
  .node_label_text .main_text {
    text-align: left;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .task_id {
    text-align: left;
    // display: flex;
    // flex-direction: row-reverse;
    font-size: smaller;
    margin-top: -5px;
    width: 100%;
  }
}
