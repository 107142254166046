@import "../colors.scss";

.stepFour-scheduling {
  .stepFour-btns-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    .stepFour-btns {
      display: flex;
      flex-direction: row;
    }
  }

  .stepFour-cards-container {
    .ant-card-head {
      border-color: $card-border;
      font-family: "Poppins";
      font-size: 16px;
      color: $cards-title-color;
    }
    .stepFour-cards-one {
      .stepFour-cards {
        background-color: $card-bg-color;
        border-bottom: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-color: $card-border;
        // height: 300px;
        align-items: center;
        justify-content: center;
        display: block;
      }
      .card-container {
        flex-direction: column;
        justify-content: space-evenly;
        .scheduling-timeline {
          font-family: "Poppins";
          font-size: 13px;
          color: $cards-title-color;
          .react-calendar-timeline {
            border-radius: 10px;
            border: 1px solid $card-border;
            background-color: $steps-bg;
            height: auto;
            padding: 0px;

            .rct-header-root {
              background-color: $timeline-color !important;
              border-top-right-radius: 10px !important;
              border-top-left-radius: 10px !important;
            }
            .rct-dateHeader-primary {
              background-color: $timeline-color !important;
              // width: 879.2px !important;
            }
            .rct-dateHeader {
              background-color: $timeline-row-color;
              // height: 60px;
            }
          }
        }
      }
    }
    .stepFour-cards-two {
      .stepFour-cards {
        background-color: $card-bg-color;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-color: $card-border;
      }
    }
    .stepFour-cards-three {
      .stepFour-cards {
        background-color: $card-bg-color;
        border-bottom: none;
        //border-top-left-radius: 10px;
        //border-top-right-radius: 10px;
        border-color: $card-border;
        // height: 300px;
        align-items: center;
        justify-content: center;
        display: block;
      }
      .card-container {
        flex-direction: column;
        justify-content: space-evenly;
        .scheduling-timeline {
          font-family: "Poppins";
          font-size: 13px;
          color: $cards-title-color;
          .react-calendar-timeline {
            border-radius: 10px;
            border: 1px solid $card-border;
            background-color: $steps-bg;
            height: auto;
            padding: 0px;

            .rct-header-root {
              background-color: $timeline-color !important;
              border-top-right-radius: 10px !important;
              border-top-left-radius: 10px !important;
            }
            .rct-dateHeader-primary {
              background-color: $timeline-color !important;
              // width: 879.2px !important;
            }
            .rct-dateHeader {
              background-color: $timeline-row-color !important;
              // height: 60px;
            }
          }
        }
      }
    }
  }
}
.holiday {
  background: rgb(128, 128, 66) !important;
  z-index: 100 !important;
}
