@import "../colors.scss";

// .ant-picker-clear {
//   fill: white !important;
//   color: white !important;
//   background-color: aliceblue;
//   margin-right: 15px;
// }

.ant-calendar-picker-clear {
  opacity: 1;
}

// .ant-picker-suffix:hover,
// .ant-picker-suffix:active {
//   background-color: transparent !important;
//   color: transparent !important;
//   fill: transparent !important;
// }

.overallReportSearch {
  .report-search-container {
    flex-direction: row;
    padding-left: 1vh;
    width: 80%;
    // background-color: red;
    justify-content: space-between;
  }

  .datepicker-form {
    margin: unset;
    .ant-form-item {
      align-items: center;
    }
    .ant-picker {
      height: 40px;
      border: 1.5px solid $search-border;
      border-radius: 6px;
      background-color: $search-bg;
    }
    .ant-picker-input > input {
      font-family: "Poppins";
      color: $search-font;
    }
    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker {
      background-color: unset;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      margin-left: 6px;
    }
    .ant-picker-suffix {
      color: $search-font;
    }
    .ant-picker-clear {
      background-color: transparent;
      color: $close-icon !important;
      background-color: $close-icon-bg;
      height: 15px;
      width: 15px;
      justify-content: center;
      align-items: center;
      flex: 1;
      display: flex;
    }
  }

  .form-field-report {
    margin: unset;
    .ant-form-item {
      align-items: center;
    }

    .ant-picker {
      height: 40px;
      border: 1.5px solid $search-border;
      border-radius: 6px;
      background-color: $search-bg;
    }
    .ant-picker-input > input {
      font-family: "Poppins";
      color: $search-font;
    }
    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker {
      background-color: unset;
    }
    .ant-picker-suffix {
      color: $search-font;
    }
    .ant-picker-clear {
      background-color: unset;
      color: $close-icon !important;
      background-color: $close-icon-bg;
      height: 15px;
      width: 15px;
      justify-content: center;
      align-items: center;
      flex: 1;
      display: flex;
    }
    // .ant-picker-clear {
    //   background-color: unset;
    //   color: $close-icon;
    //   background-color: $close-icon-bg;
    //   height: 15px;
    //   width: 15px;
    //   justify-content: center;
    //   align-items: center;
    //   flex: 1;
    //   display: flex;
    // }
  }
  .form-field-button {
    display: flex;
    // flex: 1;
    // height: 120px;
    flex-direction: column;
    justify-content: center;
    margin: unset;
    margin-right: 60px;
    // margin-top: 0px;
    button {
      height: 40px;
      background-color: $primary-color;
      border-radius: 5px;
      border: none;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      font-family: "Poppins";
      color: $search-font;
    }
    .ant-btn-primary {
      background-color: $primary-color;
      color: $button-font;
      font-family: "Poppins";
    }
  }
}
