@import "../colors.scss";

.slot-container {
  background-color: #242424;
  padding: 30px 0px 20px 30px;
}

.ant-select-arrow {
  color: #ffffff;
}

.slot-container-disable {
  display: none;
  padding: 0px;
}

.slot-details {
  color: $modal-label-color;
  font-family: "Poppins";
  font-size: 20px;
}

.slot-fields {
  color: $modal-label-color;
  font-family: "Poppins";
  font-size: 15px;
}
