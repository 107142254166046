@import "../colors.scss";
.utilization-report-container {
  margin-left: 8px;
  .search-section {
    /*margin: 0% 0 2% 0;*/
    padding: 0% 0 0 0.5%;
    /*background-color: #222230; /* #222230 */
    position: relative;
    display: flex;
    flex-direction: row;
    //   border-bottom: 1px solid #177ddc;
  }

  .row-section-details {
    // margin-bottom: 20px;
    // background-color: red;
    display: flex;
    flex: 1;
    flex-direction: row;
    // height: 100%;
    // width: 100%;
    align-items: center;
    justify-content: flex-end;
    .machineName {
      padding: 15px;
      width: 40%;
      border-radius: 10px;
      // margin-right: 30px;
      background-color: #242424;
      height: 230px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .utilDetails {
      width: 100%;
      display: flex;
      flex: 1;
      justify-content: space-around;
      // align-items: center;
    }
  }

  .row-section {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .inner-dark-section {
    padding: 20px;
    /*background-color: #222230;*/
  }

  .outer-container {
    padding-bottom: 30px;
  }

  .title-section {
    margin: 20px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }

  .daily-utilization-section {
    display: flex;
    height: 500px;
    /*background-color: #222236;*/
    width: 100%;
    flex-direction: column;
    padding-top: 25px;
  }

  .daily-utilization-chart-section {
    display: flex;
    height: 500px;
    width: 100%;
    margin: 10px;
    justify-content: center;
  }
  .machineName {
    font-family: "Poppins";
    color: $machineName;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
