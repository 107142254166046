@import "./colors.scss";

.form-field-archive .anticon {
  color: white !important;
}

.bg_col {
  background-color: #343434 !important;
}

.bg_col:hover {
  background-color: #343434;
}

.ant-dropdown-menu {
  background-color: #343434;
}

.ant-dropdown-menu-item-group-title {
  color: #fff;
  font-family: "Poppins";
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.audit-logs .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: white !important;
}

.audit-logs .ant-checkbox-wrapper:hover {
  color: white !important;
  background-color: #145ea9;
  width: 100%;
  border-radius: 2px;
  padding-left: 2px;
  padding-bottom: 1px;
}

.ant-checkbox-wrapper span {
  color: #cacaca !important;
  font-family: "Poppins" !important;
  font-size: 13px !important;
}

// .audit-logs .ant-radio-wrapper span {
//   color: #cacaca !important;
//   font-family: "Poppins" !important;
//   font-size: 13px !important;
// }

// .audit-logs
//   .ant-radio-wrapper:hover
//   .ant-radio-checked
//   .ant-radio-inner::after {
//   background-color: white !important;
// }

// .audit-logs .ant-radio-wrapper:hover .ant-radio-checked .ant-radio-inner {
//   border-color: white !important;
// }

// .audit-logs .ant-radio-wrapper:hover .ant-radio-inner {
//   border-color: white !important;
// }

// .audit-logs .ant-radio-wrapper:hover {
//   color: white !important;
//   background-color: #145ea9;
//   width: 100%;
//   border-radius: 2px;
//   padding-left: 2px;
//   padding-bottom: 1px;
// }

.audit-logs {
  a {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #cacaca !important;
  }

  .ant-picker {
    background: #1b1b1b !important;
    height: 40px;
    border-radius: 5px;
    border: 1.5px solid #444444;
  }

  .ant-picker-input > input {
    color: #ffffff !important;
  }

  .search_menu_payment_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
  //  margin-top: 2px;
    margin-left: 10px;
    margin-right: 15px;
    position: center;
  }

  //   .bg_col:hover {
  //     background-color: #2a2d3e;
  // }
}

.general-table {
  .ant-table {
    background-color: $table-bg;
    border-radius: 10px;
  }
  .ant-table-tbody > tr:last-child td {
    border-bottom-color: transparent;
  }
  .ant-table-tbody > tr > td {
    border-bottom-color: $border-color;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: $row-hover;
  }

  .expand-parent {
    background-color: $row-hover;
  }

  .ant-table-tbody
    > tr:last-child:hover:not(.ant-table-expanded-row)
    > td:first-child {
    border-bottom-left-radius: 10px;
  }
  .ant-table-tbody
    > tr:last-child:hover:not(.ant-table-expanded-row)
    > td:last-child {
    border-bottom-right-radius: 10px;
  }
  .ant-table-thead > tr > th:first-child {
    padding-left: 30px;
  }
  .ant-table-tbody > tr > td:first-child {
    padding-left: 30px;
  }
  .ant-table-tbody > tr > .ant-table-column-sort {
    background-color: unset;
  }

  .ant-table-thead > tr > th {
    background-color: $thead-color;
    padding-top: 20px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }
  .ant-table-thead > tr > th {
    border-bottom-color: $thead-border-color;
  }

  .ant-table-thead {
    .ant-table-cell {
      color: $thead-font-color;
      font-family: "Poppins";
      text-align: left;
      font-weight: 600;
    }
  }
  .ant-table-tbody {
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background-color: unset;
    }
    .ant-table-cell {
      color: $thead-font-color;
      font-family: "Poppins";
      text-align: left;
    }
    .ant-table-cell:first-child {
      color: $tbody-fontMachine-color;
      font-family: "Poppins";
      text-align: left;
      font-weight: 600;
    }
    .ant-table-cell:last-child {
      width: 140px;
      justify-content: center;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #1f1f1f;
  }
  .ant-form-item-control-input-content .ant-input {
    background-color: #1f1f1f;
  }

  .ant-col .ant-form-item-control {
    /* background-color: red; */
  }
  .ant-pagination-item {
    background-color: #222736;
    color: #c4c4c4;
  }

  .ant-pagination-item > a {
    /* background-color: #222736; */
    color: #c4c4c4;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: #222736;
    color: #c4c4c4;
  }

  .ant-table-pagination.ant-pagination {
    margin-right: 20px;
  }
}

// <--schedule page styles-->
.search_filter_lables {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #cacaca !important;
}
.close-icn {
  .ant-picker-clear {
    background-color: unset;
    color: $close-icon !important;
    background-color: $close-icon-bg;
    height: 15px;
    width: 15px;
    justify-content: center;
    align-items: center;
    flex: 1;
    display: flex;
  }
}

.row {
  display: flex;
  flex-direction: row;
  margin-left: 1px;
  justify-content: flex-start;
  align-items: center;
}

.center {
  justify-content: center;
}

.end {
  justify-content: flex-end;
  margin-right: 2px;
}

.row .ant-input-affix-wrapper > input.ant-input,
.row .ant-input-affix-wrapper {
  background-color: #1b1b1b !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
}

.row .ant-picker-suffix {
  color: white !important;
}
.row .ant-picker-separator {
  color: white !important;
}
.table-status {
  display: flex;
  justify-content: center;
  width: 110px;
  height: 23px;
  font-size: 11px;
}

.table-statusz {
  display: flex;
  justify-content: center;
  width: 130px;
  height: 23px;
  font-size: 11px;
}

.table-status .row,
.table-statusz .row {
  display: flex;
  margin-left: -10px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.table-status .row .status_text,
.table-statusz .row .status_text {
  margin-left: 5px;
  font-weight: 500;
  display: flex;
  width: 85%;
  justify-content: flex-start;
}
