@import "./colors.scss";

.general-select-multiple {
  .ant-select-selection-placeholder {
    font-family: "Poppins";
  }
  .ant-select-selection-item {
    background-color: $selected-bg;
    color: $selected-color;
    border: none;
    font-family: "Poppins";
    font-size: 13px;
  }
  .anticon-close {
    color: $selected-color;
  }
}
