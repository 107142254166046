@import "./colors.scss";

.ant-popover-inner {
  background-color: $popUp-dropdown;
  border-radius: 5px;
  border: 2px solid $popUp-border-color;
  .ant-popover-message-title {
    color: $popUp-title;
    font-family: "Poppins";
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  .ant-popover-message {
    display: flex;
    justify-content: center;
  }
  button {
    height: 30px;
    // background-color: $primary-color;
    border-radius: 5px;
    border: none;
    margin-left: 15px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    flex-direction: row;
  }
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    font-family: "Poppins";
    color: $search-font;
  }
  .ant-btn-primary {
    // background-color: $primary-color;
    color: $button-font;
    font-family: "Poppins";
    font-size: 15px;
  }
}
