.nodeData-input-modal {
  .ant-modal-body {
    padding: 24px 14px 24px 20px !important;
  }
}

.tast_ratio {
  color: rgba(255, 255, 255, 0.5) !important;
  font-family: "Poppins";
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
}

.ratio_divider .ant-divider-inner-text {
    width: 30% !important;
}