@import "../colors.scss";

.access-row {
  /*display: flex;*/
  /*justify-content: space-between;*/
  /*flex-direction: row;*/
  padding: 10px;
}
.role-names {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.role-divider {
  border-top: 1px solid #c4c4c4;
  // width: 90%;
}
.role-divider-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.auth-section-name {
  /*margin: 10px;*/
  font-size: 14pt;
  font-weight: bold;
  margin-left: 20px;
}
// .auth-tb-container {
//   // margin-bottom: 20px;
//   // background-color: red;
//   border: 1px solid red;
// }
.ant-collapse-content {
  background-color: #242424;
}
.ant-collapse {
  background-color: $collapse-panel-bg;
  border: none;
  border-radius: 10px;
}
.auth-section-name {
  color: #ffffff88;
}
.ant-collapse-arrow {
  color: #ffffff;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
  align-items: center;
  padding: 30px 30px;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0.5px solid $border-color;
}
.ant-collapse-borderless:last-child > .ant-collapse-item {
  border: none;
}
