//login Page
$backgroundColor-login: #1b1a21;
$headerColor-login: #2856da;
$headerFontColor-login: #ffffff;
$footerColor-login: #2a3042;
$inputWrapperColor-login: #2e3548;
$labelColor-login: #cacaca;
$inputTextColor-login: #898e9e;

//recover

$backgroundColor-rc: #1b1a21;
$headerColor-rc: #f05353;
$headerFontColor-rc: #ffffff;
$footerColor-rc: #2a3042;
$inputWrapperColor-rc: #2e3548;
$labelColor-rc: #cacaca;
$inputTextColor-rc: #898e9e;

//RecoverThroughEmail

$backgroundColor-rte: #1b1a21;
$headerColor-rte: #f05353;
$headerFontColor-rte: #ffffff;
$footerColor-rte: #2a3042;
$inputWrapperColor-rte: #2e3548;
$labelColor-rte: #cacaca;
$inputTextColor-rte: #898e9e;

//RecoverThroughMobile

$backgroundColor-rtm: #1b1a21;
$headerColor-rtm: #f05353;
$headerFontColor-rtm: #ffffff;
$footerColor-rtm: #2a3042;
$inputWrapperColor-rtm: #2e3548;
$labelColor-rtm: #cacaca;
$inputTextColor-rtm: #898e9e;
$highlight: #3d4457;

//Enter OTP

$backgroundColor-OTP: #1b1a21;
$headerColor-OTP: #f05353;
$headerFontColor-OTP: #ffffff;
$footerColor-OTP: #2a3042;
$inputWrapperColor-OTP: #2e3548;
$labelColor-OTP: #cacaca;
$inputTextColor-OTP: #898e9e;

//change password

$backgroundColor-cgPwd: #1b1a21;
$headerColor-cgPwd: #218e54;
$headerFontColor-cgPwd: #ffffff;
$footerColor-cgPwd: #2a3042;
$inputWrapperColor-cgPwd: #2e3548;
$labelColor-cgPwd: #cacaca;
$inputTextColor-cgPwd: #898e9e;

//header

$bg-color: #1b1b1b;
// $bg-color: #191C20;
$bg-color-secondary: #242424;
$header-font-color: #e2e5e9;
$primary-color: #073d74;
$hover-color: #949494;
$border-color: #5b5b5b;
$text-color: rgba(255, 255, 255, 0.6);
$hover-dropdown-color: #444444;
$header-border-color: rgba(155, 155, 155, 0.082);

//side navigation

$side-nav-font: #aeaeae;

//MachinesStatusTable
$table-bg: #242424;
$border-color: rgba(255, 255, 255, 0.1);
$thead-color: #1f1f1f;
$thead-border-color: rgba(217, 213, 236, 0.4);
$thead-font-color: rgba(255, 255, 255, 0.5);
$tbody-font-color: #f1ebeb;
$tbody-fontMachine-color: #f1ebeb;
$tbody-location-color: rgba(255, 255, 255, 0.6);
$row-hover: #444444;
$progressText: #cccccc;
$search-border: #373737;
$shadow-border: rgba(55, 55, 55, 0.3);
$pagination-border: rgba(146, 145, 145, 0.479);

//utilization report
$search-font: rgba(255, 255, 255, 0.411);
$search-border: rgba(255, 255, 255, 0.25);
$close-icon-bg: rgba(255, 255, 255, 0.753);
$close-icon: rgb(236, 99, 99);
$button-font: rgba(255, 255, 255, 0.836);

//utilization Report
$utilizationName: #cccccc;
$machineName: #636363;
$values: #a9a6a6;
$util-dropdown: #242424;
$hover-util-dropdown: #444444;
$calender-bg: #242424;
$calender-font-color: rgba(255, 255, 255, 0.5);
$calender-hover: rgba(255, 255, 255, 0.151);
$disabled-font: rgba(255, 255, 255, 0.068);
$border-color: rgba(255, 255, 255, 0.137);
$dropdown-color: rgba(255, 255, 255, 0.6);
$selected-item: #555555;
$table-hover: rgba(255, 255, 255, 0.6);
$search-bg: #1f1f1f;
$radio-btn: rgba(255, 255, 255, 0.6);

//user-searchBar
$button-disabled: #0d49855d;
$text-color-user: rgba(255, 255, 255, 0.6);
$delete-btn: #f18b8b;
$edit-btn: #487199;

//authorization
$text-color-des: rgba(255, 255, 255, 0.6);

//accessControl
$checkbox-bg: #073d74;
$bg-color-secondary: #242424;
$collapse-panel-bg: #1f1f1f;
$collapse-table-bg: #2c2c2c;
$access-row-hover: #2b2b2b;

//archive report
$selected-bg: #073d74;
$selected-color: rgba(255, 255, 255, 0.6);
$week-color: rgb(81, 160, 197);

//overall
$heatMap-bg: #242424;
$machine-name-text: rgba(255, 255, 255, 0.6);
$overall-border-color: rgba(255, 255, 255, 0.137);
$table-left-bg: #1f1f1f;

//bobbinManagement
$bobbin-title: rgba(255, 255, 255, 0.6);

//Raw material Management
$raw-title: rgba(255, 255, 255, 0.6);

// general-modal-popup
$header-modal-title: rgba(255, 255, 255, 0.6);
$modal-label-color: rgba(255, 255, 255, 0.5);
$modal-input-border-color: rgba(255, 255, 255, 0.37);
$modal-border-color: rgba(255, 255, 255, 0.137);
$modal-selection-color: rgba(255, 255, 255, 0.137);

// popup
$popUp-dropdown: #242424;
$popUp-title: rgba(255, 255, 255, 0.6);
$popUp-border-color: #5b5b5b;

//add machine
$add-machine-button: #073d74;
$divider-color: rgba(255, 255, 255, 0.11);

//cable process
$process-title: rgba(255, 255, 255, 0.6);
$process-bg: #242424;

//update user
$update-user-title: rgba(255, 255, 255, 0.6);

//prodction plan
$cancel-btn-bg: #ff7875;

// Scheduling
$steps-color: rgba(255, 255, 255, 0.6);
$steps-bg: #1f1f1f;

// Scheduling Step 1
$step1-color: rgba(255, 255, 255, 0.6);
$step1-label-color: rgba(255, 255, 255, 0.61);
$step1-border-color: rgba(255, 255, 255, 0.055);
$step1-bg: #1f1f1f;
$picker-border: #444444;
$error-border: #ff4d4f;

// Scheduling Step 2
$prev-btn-color: #1f1f1f;
$scroll-border-color: rgba(255, 255, 255, 0.055);
$scroll-bg: #444444;

// Scheduling Step 4
$card-bg-color: #1b1b1b;
$card-border: #444444;
$cards-title-color: rgba(255, 255, 255, 0.6);
$timeline-color: #0c4a5a;
$timeline-row-color: #097981;

//calender

$calender-bg: #1f1f1f;
$disable-btn: #923a38;
$file-delete-btn: #ff4d4f;
$calender-color: #1b1b1b;
$satSun: #0f141b6b;
$rangeOff-color: rgba(255, 184, 179, 0.116);
$today-color: #3caa4fab;
$today-day-color: #3caa4e1a;
$event-bg-color: #e44245e1;
$show-more-bg-color: #e4424588;
$calender-txt-color: rgba(255, 255, 255, 0.726);
$show-more-txt-color: rgba(255, 255, 255, 0.432);
$event-border-color: #0f61adea;
