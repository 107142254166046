@import "../colors.scss";

.calender-search-container {
  // background-color: red;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin: unset;
}

.calender-container {
  .datepicker-field {
    margin: unset;
    .ant-picker {
      height: 45px;
      border: 1.5px solid $search-border;
      border-radius: 6px;
      background-color: $search-bg;
    }
    .ant-picker-input > input {
      font-family: "Poppins";
      color: $search-font;
    }
    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker {
      background-color: unset;
    }
    .ant-picker-suffix {
      color: $search-font;
    }
    .ant-picker-clear {
      background-color: unset;
      color: $close-icon !important;
      background-color: $close-icon-bg;
      height: 15px;
      width: 15px;
      justify-content: center;
      align-items: center;
      flex: 1;
      display: flex;
    }
    .ant-form-item-label {
      display: flex;
      align-items: center;
    }
    margin-bottom: 20px;
  }
  .calender-field-button {
    display: flex;
    height: 90px;
    flex-direction: row;
    margin: unset;
    button {
      height: 45px;
      border-radius: 6px;
      border: none;
      background-color: $primary-color;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      font-family: "Poppins";
      color: $search-font;
    }
    .ant-btn-primary {
      background-color: $primary-color;
      color: $button-font;
      font-family: "Poppins";
    }
  }
  .calender-text-input {
    width: 55%;
    height: 45px;
    .ant-form-item-label {
      display: flex;
      padding-top: 8px;
      // align-items: center;
    }
    .ant-input {
      height: 45px;
      border: 1.5px solid $search-border;
      border-radius: 6px;
      background-color: $search-bg;
      font-family: "Poppins";
      color: $search-font;
      align-items: center;
      display: flex;
    }
    .ant-input::placeholder {
      color: $modal-label-color !important;
      font-family: "Poppins";
      font-size: 15px;
      font-weight: 300;
      padding-top: 0px;
      align-items: center;
    }
  }
  .holiday-update {
    .title-txt-one,
    .title-txt-two {
      margin-bottom: 35px;
      h2 {
        font-family: "Poppins";
        color: $search-font;
      }
    }
    .upload-container {
      display: flex;
      flex-direction: row;
      font-family: "Poppins";
      color: $search-font;
      // justify-content: space-between;
      // background-color: red;
      width: 50%;
      .ant-btn-primary[disabled],
      .ant-btn-primary[disabled]:hover,
      .ant-btn-primary[disabled]:focus,
      .ant-btn-primary[disabled]:active {
        background-color: $disable-btn;
        color: $search-font;
      }
      button {
        font-size: 15px;
        height: 45px;
        border-radius: 5px;
        border: none;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        display: flex;
      }
      .upload-btn {
        // margin-top: 20px;
      }
      .ant-upload-list-picture-container {
        width: 145%;
        font-family: "Poppins";
        color: $search-font;
      }
      .ant-upload-list-item-name {
        font-size: 17px;
      }
      .ant-upload-list-picture .ant-upload-list-item,
      .ant-upload-list-picture-card .ant-upload-list-item {
        border: 1.5px solid $search-border;
      }
      .ant-upload-list-item-card-actions .anticon {
        font-size: 20px;
        color: $file-delete-btn;
      }
    }
  }
  .calender-view-container {
    margin-top: 30px;
    background-color: $calender-bg;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 10px;
    .rbc-month-view,
    .rbc-time-view {
      background-color: $calender-color;
      border: 2px solid $search-border;
      border-radius: 8px;
      height: 50px;
    }
    .rbc-day-bg + .rbc-day-bg {
      border-left: 1px solid $search-border;
    }
    .rbc-date-cell {
      font-family: "Poppins";
      color: $search-font;
      font-weight: 300;
      font-size: 18px;
    }
    .rbc-off-range-bg {
      color: red !important;
    }

    .rbc-month-row + .rbc-month-row {
      border-top: 1px solid $search-border;
    }
    .rbc-time-content > * + * > *,
    .rbc-time-content {
      border-top: unset;
      border-bottom: unset;
      border-left: 1px solid $search-border;
    }
    .rbc-time-header.rbc-overflowing {
      border-right: unset;
    }
    .rbc-time-header-content {
      border-left: unset;
    }
    .rbc-month-header {
      height: 50px;
    }
    .rbc-allday-cell {
      background-color: $calender-color;
    }
    .rbc-header {
      justify-content: center;
      display: flex;
      align-items: center;
      font-family: "Poppins";
      color: $search-font;
      font-weight: 300;
      font-size: 15px;
      border-bottom: 3px solid $search-border;
    }
    .rbc-header:first-child {
      border-top-left-radius: 8px;
    }
    .rbc-header:last-child {
      border-top-right-radius: 8px;
    }
    .rbc-day-bg:last-child:not(.rbc-off-range-bg) {
      background-color: $satSun;
    }
    .rbc-day-bg:first-child:not(.rbc-off-range-bg) {
      background-color: $satSun;
    }
    .rbc-off-range-bg {
      background-color: $rangeOff-color;
    }
    .rbc-today {
      background-color: $today-color;
    }

    .rbc-event,
    .rbc-day-slot .rbc-background-event {
      background-color: $event-bg-color;
      border-radius: 3px;
      height: 35px;
      font-family: "Poppins";
      color: $calender-txt-color;
    }
    .rbc-event:focus,
    .rbc-day-slot .rbc-background-event:focus {
      outline: unset;
      border: 2px solid $event-border-color;
    }
    .rbc-toolbar {
      margin-bottom: 40px;
      justify-content: center;
    }
    .rbc-toolbar-label {
      font-family: "Poppins";
      color: $calender-txt-color;
      font-weight: 500;
      font-size: 22px;
    }
    .rbc-toolbar button {
      border-radius: 4px;
      margin-right: 5px;
      height: 35px;
      border: none;
      background-color: $primary-color;
      font-family: "Poppins";
      color: $calender-txt-color;
      font-size: 14px;
    }
    .rbc-btn-group:first-child {
      button:first-child {
        background-color: $today-color;
      }
    }
    .rbc-show-more {
      background-color: $show-more-bg-color;
      color: $show-more-txt-color;
    }
    .rbc-active {
      border: 3px solid $today-color !important;
    }
    .rbc-time-column:not(.rbc-time-gutter) {
      height: 2000px;
      font-family: "Poppins";
      color: $calender-txt-color;
      background-color: $today-day-color;
    }
    .rbc-time-gutter {
      height: 2000px;
      font-family: "Poppins";
      color: $calender-txt-color;
    }
    .rbc-timeslot-group:last-child {
      border-bottom: unset;
    }
    .rbc-time-column .rbc-timeslot-group {
      border-bottom: 1px solid $search-border;
    }
    .rbc-day-slot .rbc-time-slot {
      border-top: 1px solid $search-border;
    }
    .rbc-header + .rbc-header {
      border-left: 1px solid $search-border;
    }
    .rbc-time-content,
    .rbc-time-header-gutter {
      visibility: hidden;
      display: none;
    }
    .rbc-time-header .rbc-overflowing {
      margin-right: 0px;
    }
    .rbc-time-header {
      display: flex;
      flex: 1;
      overflow: auto;
    }
    .rbc-time-header-cell {
      height: 50px;
    }
    .rbc-agenda-view .rbc-agenda-table thead {
      display: none;
    }
    .rbc-agenda-view .rbc-agenda-table tr .rbc-agenda-time-cell {
      display: none;
    }
    .rbc-agenda-table {
      border: 1px solid $search-border;
      font-family: "Poppins";
      color: $calender-txt-color;
      border-radius: 10px;
    }
    .rbc-agenda-event-cell {
      height: 50px;
    }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
      border-top: 1px solid $search-border;
    }
    .rbc-agenda-event-cell {
      border-left: 1px solid $search-border;
    }
    // .rbc-agenda-view {
    //   border: 1px solid $search-border;
    //   border-radius: 10px;
    // }
    .rbc-agenda-time-cell {
      border-left: 1px solid $search-border;
      font-family: "Poppins";
      color: $calender-txt-color;
      align-items: center;
    }
    .rbc-agenda-event-cell {
      align-items: center;
      display: flex;
    }
  }
}
