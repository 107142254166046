@import "../../pages/colors.scss";

.no-notification {
  margin-top:100px;
}

.notification-title-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 450px;
  margin: 10px 10px 10px 10px;
  padding: 0 2px 0 2px;
  border-bottom: 1px solid rgb(85, 85, 85);

  .title {
    color: $text-color;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
  }

  .actions {
    color: $text-color;
    font-family: "Poppins";
    font-size: small;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .label {
        padding-right: 15px;
    }
  }
}
