@import "../colors.scss";

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.components-table-demo-nested {
  margin-top: -15px;
}

.main-container-machines {
  .machine-search-bar {
    background-color: unset;
    width: 100%;
  }
  .ant-input-affix-wrapper {
    background-color: unset;
    border: 1px solid $search-border;
  }
  .ant-input-affix-wrapper:hover {
    border: 1px solid $search-border;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px $shadow-border;
  }
  .ant-input-affix-wrapper > input.ant-input {
    background-color: unset;
    font-weight: 300;
    font-size: 14px;
    color: $tbody-location-color;
    font-family: "Poppins";
  }
  .ant-input::placeholder {
    color: $tbody-location-color;
    font-family: "Poppins";
    font-weight: 300;
    font-size: 14px;
  }
  .btn_section {
    // border: 1px solid darkcyan;
    // width: 20%;
  }
  .filter_section {
    // border: 1px solid darkcyan;
    // width: 80%;
    display: flex;
    flex-direction: row-reverse;
  }
  .actions_row {
    width: 100%;
  }
  .actions_filter_row {
    width: 100%;
    // border: 1px solid sandybrown;
    .searchby_filter {
      // border: 1px solid aliceblue;
      height: 100%;
      display: flex;
      align-items: center;
      color: #cacaca !important;
    }
    .searchby_filter a {
      color: #cacaca !important;
    }
    .select-color option {
      display: inline-block;
      height: 40px;
      float: left;
    }
    .ant-select-multiple .ant-select-selection-item {
      background-color: rgba(91, 91, 91, 0.5);
      color: #ffffff;
      border: 1px solid rgba(91, 91, 91, 0.5);
    }
    .ant-select-selector {
      height: 45px !important;
      border-radius: 5px !important;
    }
  }
  .filter_plant {
    height: 100%;
    display: flex;
    align-items: center;
    .ant-select-selector {
      height: 45px;
      border-radius: 6px;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      font-family: "Poppins";
      color: $search-font;
    }
    .ant-form-item-label {
      display: flex;
      align-items: center;
    }
  }
  .filter_plant span {
    margin-right: 15px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #cacaca !important;
  }
}

.row {
    display: flex;
    flex-direction: row;
    margin-left: 1px;
    justify-content: flex-start;
    align-items: center;
  }
