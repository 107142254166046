.react-calendar-timeline .rct-header-root {
  background-color: transparent !important;
  color: white;
  border-bottom: 2px solid rgba(229, 229, 229, 0.2);
}

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /*border-bottom: 1px solid #bbb;*/
  cursor: pointer;
  font-size: 14px;
  background-color: transparent;
  border: none;
  border-left: 2px solid #bbbbbb26;

}
.timeline-item{
  /*background: #13637C;*/
  border-radius: 7px;
  border: none;
  /*padding-left: 4px;*/
  /*margin: 5px;*/
}
.rct-vertical-lines .rct-vl{
  /*border-left: none!important;*/
  border-left: 1px solid rgba(187, 187, 187, 0.04) !important;

  /*background-color: #faad14!important;*/
  /*display: block;*/
}
.rct-vertical-lines .rct-vl.rct-day-0 {
    background: rgba(191, 191, 191, 0.27) !important;
  }
.rct-vertical-lines .rct-vl.rct-day-6{
  background: rgba(191, 191, 191, 0.27) !important;
}
/*.rct-vertical-lines {*/
/*.rct-vl {*/
/*  position: absolute;*/
/*  border-left: 1px solid $border-color;*/
/*  z-index: 30;*/
/*&.rct-vl-first {*/
/*   border-left-width: 2px;*/
/* }*/
/*&.rct-day-6,*/
/*&.rct-day-0 {*/
/*   background: $weekend;*/
/* }*/
/*}*/
/*}*/
.react-calendar-timeline{
  /*background-color: chartreuse;*/
  height: 100%;
  padding: 10px;
}
.rct-horizontal-lines{
  /*background-color: #faad14!important;*/
  /*display: none;*/
}
.react-calendar-timeline .rct-scroll{
  /*margin: 10px;*/
  /*background-color: red;*/
  /*display: flex;*/
  /*justify-content: center;*/
  align-items: center;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row{
  border-bottom: 2px solid rgba(229, 229, 229, 0.2);
}
.react-calendar-timeline .rct-sidebar{
  border-right: 2px solid rgba(229, 229, 229, 0.2);
}
.highlight{
  background-color: rgba(184, 173, 173, 0.12);
  /*display: none;*/
  /*float: right;*/
  /*position: absolute;*/
  /*height: 0 !important;*/
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd{
  border-bottom: 2px solid rgba(229, 229, 229, 0.2);
}
.react-calendar-timeline .rct-calendar-header{
  border: none;
}
.holiday {
  background: rgba(243, 227, 84, 0.32) !important;
}