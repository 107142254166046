@import "../colors.scss";

.stepOne-scheduling {
  .ant-divider-inner-text {
    color: $step1-color;
    font-family: "Poppins";
    font-size: 20px;
  }
  .ant-divider-horizontal.ant-divider-with-text {
    margin-bottom: 20px;
    border-top-color: $step1-border-color;
  }
  .form-row {
    margin-bottom: 10px;
    .ant-form-item-control-input-content .ant-input {
      height: 45px;
    }
    .ant-form-item-label > label {
      color: $step1-label-color !important;
      margin-bottom: 5px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 45px;
      border-radius: 5px;
    }
    .ant-picker {
      height: 45px;
      border-radius: 5px;
      border: 1.5px solid $picker-border;
    }
    .ant-picker-input {
      .ant-picker-suffix {
        color: $step1-color;
      }
    }
    .ant-input-group-addon {
      border: none;
      background-color: unset;
      color: $step1-color;
      font-family: "Poppins";
      font-size: 20px;
    }
    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker,
    .ant-form-item-has-error .ant-input {
      border-color: $error-border;
    }
    .ant-select-selection-item {
      color: green;
    }
    .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-error .ant-picker:not([disabled]):hover {
      background-color: unset;
    }
  }
  .ant-checkbox-wrapper {
    span {
      color: $step1-color;
      font-family: "Poppins";
      font-size: 15px;
    }
    .ant-checkbox-inner {
      background-color: unset;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $checkbox-bg;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border: 1px solid $checkbox-bg;
    }
  }
  .ant-radio-wrapper {
    margin-right: 45px;
    span {
      //   border: none;
      background-color: unset;
      color: $step1-color;
      font-family: "Poppins";
      font-size: 15px;
    }
  }
  .ant-btn-primary {
    color: $step1-color;
    padding: 20px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    color: $step1-color;
    font-family: "Poppins";
    font-size: 15px;
    margin-top: 20px;
  }
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: unset;
  }
}

.ant-select-selection-placeholder {
  color: #444444 !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}
.ant-picker-input > input::placeholder {
  color: #444444 !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}
.gant-tooltip {
  color: $step1-color;
  font-family: "Poppins";
  font-size: 13px;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  color: $step1-label-color;
  font-family: "Poppins";
}
.ant-picker-panel-container
  .ant-picker-body
  .ant-picker-content
  tbody
  tr
  .ant-picker-cell-inner {
  color: $step1-label-color;
  font-family: "Poppins";
}
.ant-picker-panel-container
  .ant-picker-body
  .ant-picker-content
  tbody
  tr
  .ant-picker-cell-inner:hover {
  background: $checkbox-bg;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: $checkbox-bg;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  background: $checkbox-bg;
}

// .quantity
//   .ant-form-item-explain
//   .ant-form-item-explain-error:not(:first-child) {
//   display: none !important;
// }

// overides
// .ant-form-item-has-error .ant-form-item-has-error {
//   border: 1px solid #ff4d4f !important;
// }
