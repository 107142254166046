@import "~antd/dist/antd.css";
/* @import '~ant-design-pro/dist/ant-design-pro.css';
@import '~tui-chart/dist/tui-chart.css'; */
@import "./css/bootstrap-theme.css";
@import "./css/bootstrap-theme.min.css";
@import "./css/bootstrap.css";
@import "./css/bootstrap.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}

.rbc-off-range-bg {
  background-color: #363535 !important;
}

.rbc-today {
  background-color: #5f7a8f;
}
.ant-divider, .ant-divider-horizontal {
  border: 0px solid rgba(255, 255, 255, 0.411) !important;
}

.ant-empty-description {
  color: white;
}