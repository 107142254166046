@import "../colors.scss";

.inner-dark-section {
  .newProject-title {
    background-color: $steps-bg;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 5px;
    .ant-steps {
      // display: block;
      padding-left: 20px;
      padding-right: 20px;
    }
    .ant-steps-item-title {
      font-family: "Poppins";
      font-size: 14px;
    }
    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: $steps-color;
    }
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: $steps-color;
    }
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: $steps-color;
    }
    // .ant-steps-item-finish .ant-steps-item-icon {
    //   border: none;
    // }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: #218e54;
    }
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: #218e54;
    }
    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: unset;
    }
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: unset;
    }
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-icon
      .ant-steps-icon {
      color: $steps-color;
      font-family: "Poppins";
      font-size: 20px;
    }
    .ant-steps-item-wait .ant-steps-item-icon {
      background-color: unset;
      border-color: $steps-color;
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      color: $steps-color;
      font-family: "Poppins";
      font-size: 20px;
    }
  }
  .steps-section {
    // display: flex;
    justify-content: center;
    background-color: $steps-bg;
    padding: 50px;
    border-radius: 5px;
  }
}
