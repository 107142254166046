@import "../pages/colors.scss";

.site-layout-background {
  // background-color: green;
  background-color: $bg-color;
  padding: 0px 0px 0px 0px;
  min-height: 1000px;
  .content-container {
    padding: 40px;
    height: 100%;
  }
  .content-header-container {
    padding: 0px;
    margin-bottom: 0px;
    // margin-left: 21px;
    // margin-top: 20px;
  }
  .h2-title {
    font-size: 25px;
    font-family: "Poppins";
    font-weight: 600;
    color: $header-font-color;
    text-decoration: none;
  }
}
