.notification-item-main-component {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 3px;
  margin-bottom: 5px;
  background-color: #313131;
  // height: 150px;
  width: 450px;
  padding: 0 2px 0 2px;

  .img-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 2%;
    margin-top: 5px;
  }

  .details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 15px;
    border-radius: 3px;

    .header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      // height: 40px;
      margin-bottom: 3px;
      padding-top: 5px;
      

      .title {
        display: flex;
        flex-direction: row;
        width: 85%;
        margin-bottom: 3px;
        font-size: 12px;
        font-weight: 600;
        white-space: initial;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .actions {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        width: 15%;
      }
    }

    .body {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 12px;
      white-space: initial;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;
    }

    .bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 10px;
      font-weight: 400;
    }
  }
}
