@import "../../pages/colors.scss";

.notification-bottom-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 450px;
  margin: 10px 10px 10px 10px;
  padding: 15px 2px 0 2px;
  border-top: 1px solid rgb(67, 67, 67);

  .overflow-notification {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      width: 200px;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $text-color;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 12px;
    }
  }

  .view-all {
    margin-top: 15px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: right;
    color: $text-color;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 12px;

    a {
      color: #337ab7;
      text-decoration: none;
    }
  }
}
