@import "../colors.scss";

.average-table-view {
  .ant-table-tbody {
    .ant-table-cell {
      color: $thead-font-color;
      font-family: "Poppins";
      text-align: left;
    }
    .ant-table-cell:first-child {
      color: $tbody-fontMachine-color;
      font-family: "Poppins";
      text-align: left;
      font-weight: 400;
    }
    .ant-table-cell:last-child {
      width: 140px;
      justify-content: center;
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      color: $thead-font-color;
      font-family: "Poppins";
      text-align: left;
      font-weight: 600;
    }
  }
  .ant-table {
    background-color: unset;
    border-radius: 10px;
  }
  .ant-table-tbody > tr:last-child td {
    border-bottom-color: transparent;
  }
  .ant-table-tbody > tr > td:not(:first-child) {
    background-color: $table-bg;
  }
  .ant-table-tbody > tr > td {
    border-bottom-color: $border-color;
  }
  .ant-table-tbody > tr:hover > td {
    background: $row-hover;
  }
  .ant-table-thead > tr > th:first-child {
    padding-left: 30px;
  }
  .ant-table-tbody > tr > td:first-child {
    padding-left: 30px;
    background-color: $thead-color;
    // margin-right: 10px;
  }
  .ant-table-thead > tr > th {
    background-color: $thead-color;
    padding-top: 20px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }
  .ant-table-container table > tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 10px;
  }
  .ant-table-container table > tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 10px;
  }
  .ant-table-thead > tr > th {
    border-bottom-color: $thead-border-color;
  }
  .ant-table-wrapper {
    margin-left: -30px;
  }
}
