/* @import '~antd/dist/antd.css'; */
/* @import '~ant-design-pro/dist/ant-design-pro.css'; */
@import "~antd/dist/antd.less";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.todoContainer {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.site-layout-background {
  background: #fff;
  padding: 0 24px 24px;
  min-height: 1080px;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background-color: #1f1f1f;
}

.modal-button .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.79);
}

.glb-form-button {
  border-radius: 5px;
}
.warning-text {
  font-size: 30px;
  color: #faad14;
}
.txt-title-auth {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  color: white;
}
.txt-desc-auth {
  font-size: 22px;
  line-height: 22px;
  color: white;
}
.txt404 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 32px;
  /* identical to box height, or 50% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #e3e4e6;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}
.ant-select-selector {
  background-color: #222736;
}
.sub-text {
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 16px;
}
.subHeading-container {
  margin-left: 18px;
  margin-bottom: 20px;
}

@primary-color: #073D74;