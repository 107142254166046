.rowClassName-demo {
  // background-color: cadetblue;
 // border-radius: 10px;
  //  border-color: chartreuse;
//  border-width: 3px;
 // border-style: solid;
 color: rgba(255, 255, 255, 0.5);
}

.rowClassName-demo td:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rowClassName-demo td:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
