@import "../colors.scss";

.inner-dark-section {
  .ascDescNotSelected {
    font-size: 18px;
    padding: 5px 13px 0px 0px;
    color: #898e9e;
  }

  .ascDescSelected {
    font-size: 18px;
    padding: 5px 13px 0px 0px;
    color: #ffffff;
  }

  .form-field-archive {
    // margin: 3vh 0 0 0;
    height: 75px;
    max-height: 75px;
    display: flex;
    // background-color: red;
    align-items: center;
    justify-content: center;
    .ant-form-item {
      align-items: center;
    }
  }

  .form-field-archive-interval {
    // margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    height: 75px;
    // background-color: red;
    .ant-form-item {
      display: flex;
      align-items: center;
    }
    .form-button {
      height: 40px;
      border-radius: 5px;
      background-color: $selected-bg;
      color: $selected-color;
      font-family: "Poppins";
      border: none;
    }
    .ant-picker-clear {
      background-color: unset;
      color: $close-icon !important;
      background-color: $close-icon-bg;
    }
  }
  .form-field-archive-interval .ant-picker-separator,
  .form-field-archive-interval .ant-picker-suffix {
    color: #ffffff;
  }
  .ant-picker {
    background-color: unset;
  }
  .ant-picker-input input {
    color: #ffffff;
  }

  .search-section {
    width: 100% !important;
    background-color: #1b1b1b;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
      align-items: center;
      border-radius: 5px;
      // background-color: unset;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) {
    background-color: unset;
  }
  .ant-form-item-control-input-content .ant-input {
    height: 40px;
    align-items: center;
    border-radius: 5px;
    border: 1.5px solid #444444;
  }
  .ant-row .ant-form-item .ant-form-item-with-help .ant-form-item-has-error {
    margin: 0;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: $dropdown-color;
    font-family: "Poppins";
    font-weight: 300;
    line-height: 25px;
    font-size: 14px;
  }
  .ant-form-item-control-input-content {
    input {
      color: $dropdown-color;
      font-family: "Poppins";
      font-weight: 300;
      line-height: 25px;
      font-size: 14px;
      background-color: unset;
    }
    .ant-input::placeholder {
      color: $dropdown-color;
      font-family: "Poppins";
      font-weight: 300;
      line-height: 25px;
      font-size: 14px;
    }
  }
  .search-section {
    width: 100% !important;
    .ant-picker-range {
      height: 40px;
      border-radius: 5px;
      border: 1.5px solid #444444;
    }
  }
}
.form-field-plant-multiple {
  .ant-select-selection-placeholder {
    font-family: "Poppins";
  }
  .ant-select-selection-item {
    background-color: $selected-bg;
    color: $selected-color;
    border: none;
    font-family: "Poppins";
    font-size: 13px;
  }
  .anticon-close {
    color: $selected-color;
  }
}
.ant-picker-week-panel-row:hover td {
  background-color: $selected-bg;
}
.ant-picker-week-panel-row:hover td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ant-picker-week-panel-row:hover td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ant-picker-body tbody > tr > td {
  color: $week-color;
}

.ant-picker-panel-container
  .ant-picker-body
  .ant-picker-content
  tbody
  tr
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
  .ant-picker-cell-inner,
.ant-picker-panel-container
  .ant-picker-body
  .ant-picker-content
  tbody
  tr
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background-color: $selected-bg;
}
