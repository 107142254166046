.page-header-container {
    padding-top: 3vh;
    padding-left: 0.5vw;
    padding-bottom: 4vh;
    /*background-color: #222230;*/
}

.page-header {
    font-size: 24px;
    fontWeight: bold;
    margin-bottom: 0;
}