@import "../colors.scss";

.view-stepOne {
  border: 1px solid red;
  padding: 50px;
  border-radius: 10px;
  border-color: $card-border;
}
.view-stepOne-header {
  border: 1px solid red;
  padding: 50px 20px;
  border-radius: 10px;
  border-color: $card-border;
  margin-bottom: 20px;
  .ant-divider-inner-text {
    color: $step1-color;
    font-family: "Poppins";
    font-size: 20px;
  }
  .ant-btn-primary[disabled] {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: rgba(187, 187, 187, 0.79); // need to change
  }
  .form-row-header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    row-gap: 0px;
    justify-content: space-between;
  }
  .form-row-header-btn {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .ant-form-item-label > label {
    margin-right: 20px;
    justify-content: flex-start;
    display: flex;
  }
  .status-tag {
    color: red;
    display: flex;
    flex: 1;
    width: 200px;
    justify-content: center;
    align-items: center;
  }
}

.ant-progress-text {
  color: #ffffff;
}

.form-row .ant-btn-primary[disabled] {
  background-color: $primary-color;
  border: 1px solid $primary-color;
}

.view-container {
  .stepOne-scheduling {
    .ant-divider-inner-text {
      color: $step1-color;
      font-family: "Poppins";
      font-size: 20px;
    }
    .ant-divider-horizontal.ant-divider-with-text {
      margin-bottom: 20px;
      border-top-color: $step1-border-color;
    }
    .form-row {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      .ant-form-item-control-input-content .ant-input {
        height: 45px;
      }
      .ant-form-item-label > label {
        color: $step1-label-color !important;
        margin-bottom: 5px;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 45px;
        border-radius: 5px;
      }
      .ant-picker {
        height: 45px;
        border-radius: 5px;
        border: 1.5px solid $picker-border;
      }
      .ant-picker-input {
        .ant-picker-suffix {
          color: $step1-color;
        }
      }
      .ant-input-group-addon {
        border: none;
        background-color: unset;
        color: $step1-color;
        font-family: "Poppins";
        font-size: 20px;
      }
      .ant-form-item-has-error .ant-input-number,
      .ant-form-item-has-error .ant-picker {
        border-color: $error-border;
      }
      .ant-select-selection-item {
        color: $step1-color;
      }
      .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
      .ant-form-item-has-error .ant-picker:not([disabled]):hover {
        background-color: unset;
      }
    }

    .ant-checkbox-wrapper {
      span {
        color: $step1-color;
        font-family: "Poppins";
        font-size: 15px;
      }
      .ant-checkbox-inner {
        background-color: unset;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $checkbox-bg;
      }
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: 1px solid $checkbox-bg;
      }
    }
    .ant-radio-wrapper {
      margin-right: 45px;
      span {
        //   border: none;
        background-color: unset;
        color: $step1-color;
        font-family: "Poppins";
        font-size: 15px;
      }
    }
    .ant-btn-primary {
      color: $step1-color;
      padding: 20px;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex: 1;
      color: $step1-color;
      font-family: "Poppins";
      font-size: 15px;
      margin-top: 20px;
    }
    .ant-form-item-has-error
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: unset;
    }
    // .ant-form-vertical .ant-form-item {
    //   display: flex;
    //   flex-direction: row !important;
    // }
  }

  .back-btn {
    margin-bottom: 20px;
  }

  .ant-select-selection-placeholder {
    color: #444444 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
  }
  .ant-picker-input > input::placeholder {
    color: #444444 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
  }

  .ant-input,
  .ant-picker {
    background-color: unset !important;
    height: 45px;
    border-radius: 5px;
    border: 1.5px solid $picker-border;
    color: $step1-color;
    font-family: "Poppins";
    font-size: 15px;
  }
  .ant-input::placeholder {
    color: #777474 !important;
    font-size: 15px !important;
    font-family: "Poppins";
    font-weight: 500 !important;
  }
  .ant-picker-input > input {
    font-family: "Poppins";
    font-size: 15px;
    color: $step1-color;
  }

  .stepFour-cards-view-container {
    .ant-card-head {
      border-color: $card-border;
      font-family: "Poppins";
      font-size: 16px;
      color: $cards-title-color;
    }
    .stepFour-cards-view-one {
      .stepFour-cards {
        background-color: $card-bg-color;
        border-bottom: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-color: $card-border;
        // height: 300px;
        align-items: center;
        justify-content: center;
        display: block;
      }
      .card-container {
        flex-direction: column;
        justify-content: space-evenly;
        .scheduling-timeline {
          font-family: "Poppins";
          font-size: 13px;
          color: $cards-title-color;
          .react-calendar-timeline {
            border-radius: 10px;
            border: 1px solid $card-border;
            background-color: $steps-bg;
            height: auto;
            padding: 0px;

            .rct-header-root {
              background-color: $timeline-color !important;
              border-top-right-radius: 10px !important;
              border-top-left-radius: 10px !important;
            }
            .rct-dateHeader-primary {
              background-color: $timeline-color !important;
              // width: 879.2px !important;
            }
            .rct-dateHeader {
              background-color: $timeline-row-color !important;
              // height: 60px;
            }
          }
        }
      }
    }
    .stepFour-cards-view-two {
      .stepFour-cards {
        background-color: $card-bg-color;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-color: $card-border;
      }
    }
    .stepFour-cards-view-three {
      .stepFour-cards {
        background-color: $card-bg-color;
        border-bottom: none;
        //border-top-left-radius: 10px;
        //border-top-right-radius: 10px;
        border-color: $card-border;
        // height: 300px;
        align-items: center;
        justify-content: center;
        display: block;
      }
      .card-container {
        flex-direction: column;
        justify-content: space-evenly;
        .scheduling-timeline {
          font-family: "Poppins";
          font-size: 13px;
          color: $cards-title-color;
          .react-calendar-timeline {
            border-radius: 10px;
            border: 1px solid $card-border;
            background-color: $steps-bg;
            height: auto;
            padding: 0px;

            .rct-header-root {
              background-color: $timeline-color !important;
              border-top-right-radius: 10px !important;
              border-top-left-radius: 10px !important;
            }
            .rct-dateHeader-primary {
              background-color: $timeline-color !important;
              // width: 879.2px !important;
            }
            .rct-dateHeader {
              background-color: $timeline-row-color !important;
              // height: 60px;
            }
          }
        }
      }
    }
  }
}

.Canvas-module_container__22cN4 .Canvas-module_pannable__1d7xd {
  width: 220% !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: unset;
}

.ant-checkbox-wrapper-disabled {
  // background-color: red !important;
  .ant-checkbox-disabled .ant-checkbox-inner {
    // background-color: red !important;
    border: none !important;
    .ant-checkbox-inner::after {
      border: 1px solid red !important;
      opacity: 0 !important;
    }
  }
}
.gant-tooltip {
  color: $step1-color;
  font-family: "Poppins";
  font-size: 13px;
}
.ant-popover-title {
  color: $step1-color;
}
.ant-card,
.ant-card-bordered {
  margin-bottom: 0px !important;
}
.collapse-container {
  margin-top: 30px;
  margin-bottom: 30px;
  // border: 1px solid red;
  // border-color: white;
  //border-radius: 12px;
  // border-color: $card-border;
  background: $card-bg-color;

  font-family: "Poppins";
  font-size: 16px;
  color: $cards-title-color;
  .ant-card-head-title {
    color: #ffffff99 !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #ffffff99;
    background: unset !important;
    border-radius: 10px;
    border: 1px solid $card-border;
  }
  .stepFour-cards-view-one {
    .stepFour-cards {
      background-color: $card-bg-color;
      border-bottom: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-color: $card-border;
      // height: 300px;
      align-items: center;
      justify-content: center;
      display: block;
    }
    .card-container {
      flex-direction: column;
      justify-content: space-evenly;
      .scheduling-timeline {
        font-family: "Poppins";
        font-size: 13px;
        color: $cards-title-color;
        .react-calendar-timeline {
          border-radius: 10px;
          border: 1px solid $card-border;
          background-color: $steps-bg;
          height: auto;
          padding: 0px;

          .rct-header-root {
            background-color: $timeline-color !important;
            border-top-right-radius: 10px !important;
            border-top-left-radius: 10px !important;
          }
          .rct-dateHeader-primary {
            background-color: $timeline-color !important;
            // width: 879.2px !important;
          }
          .rct-dateHeader {
            background-color: $timeline-row-color !important;
            // height: 60px;
          }
        }
      }
    }
  }
  .stepFour-cards-view-two {
    .stepFour-cards {
      background-color: $card-bg-color;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-color: $card-border;
    }
  }
  .stepFour-cards-view-three {
    .stepFour-cards {
      background-color: $card-bg-color;
      border-bottom: none;
      //border-top-left-radius: 10px;
      //border-top-right-radius: 10px;
      border-color: $card-border;
      // height: 300px;
      align-items: center;
      justify-content: center;
      display: block;
    }
    .card-container {
      flex-direction: column;
      justify-content: space-evenly;
      .scheduling-timeline {
        font-family: "Poppins";
        font-size: 13px;
        color: $cards-title-color;
        .react-calendar-timeline {
          border-radius: 10px;
          border: 1px solid $card-border;
          background-color: $steps-bg;
          height: auto;
          padding: 0px;

          .rct-header-root {
            background-color: $timeline-color !important;
            border-top-right-radius: 10px !important;
            border-top-left-radius: 10px !important;
          }
          .rct-dateHeader-primary {
            background-color: $timeline-color !important;
            // width: 879.2px !important;
          }
          .rct-dateHeader {
            background-color: $timeline-row-color !important;
            // height: 60px;
          }
        }
      }
    }
  }
  .collapse-parent {
    background: $card-bg-color;
  }
  .ant-card,
  .ant-card-bordered {
    margin-bottom: 30px !important;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: unset;
    margin-bottom: 30px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
    border: 1px solid $card-border;
    border-radius: 10px;
    margin-top: 10px;
  }

  .ant-collapse-content {
    background-color: $card-bg-color;
    border-top: unset;
  }
  .collapse-container
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    background: #444444;
    font-size: 20px;
  }
}
.status-tag {
  background-color: rgb(45, 183, 245);
  height: 25px;
  width: 100px;
  font-family: "Poppins";
  font-size: 13px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.label-text {
  font-family: "Poppins";
  font-size: 14px;
  color: $step1-color;
}

.viewMore-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.components-table-demo-row {
  border-radius: 0;
}
