@import "../colors.scss";

.checkbox-access .ant-checkbox-wrapper span {
  color: #ffffff;
  font-size: 15px;
}

.access-row {
  background-color: $bg-color-secondary;
  border-radius: 5px;
  margin-bottom: 20px;
  display: block;
  align-items: center;
  justify-content: center;
  .description-container {
    color: $text-color-des;
    font-size: 17px;
    font-family: "Poppins";
    font-weight: 500;
  }
  // .checkbox-access {
  //   margin-bottom: 30px;
  // }
}

.table-container-accessControl {
  .ant-table {
    background-color: $table-bg;
    border-radius: 10px;
  }
  .ant-table-tbody > tr:last-child td {
    border-bottom-color: transparent;
  }
  .ant-table-tbody > tr > td {
    border-bottom-color: $border-color;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: $access-row-hover;
  }

  .expand-parent {
    background-color: $row-hover;
  }

  .ant-table-tbody
    > tr:last-child:hover:not(.ant-table-expanded-row)
    > td:first-child {
    border-bottom-left-radius: 10px;
  }
  .ant-table-tbody
    > tr:last-child:hover:not(.ant-table-expanded-row)
    > td:last-child {
    border-bottom-right-radius: 10px;
  }
  .ant-table-thead > tr > th:first-child {
    padding-left: 30px;
  }
  .ant-table-tbody > tr > td:first-child {
    padding-left: 30px;
  }
  .ant-table-thead > tr > th {
    background-color: $collapse-table-bg;
    padding-top: 20px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }
  .ant-table-thead > tr > th {
    border-bottom-color: $thead-border-color;
  }
  .ant-table-thead {
    .ant-table-cell {
      color: $thead-font-color;
      font-family: "Poppins";
      text-align: left;
      font-weight: 600;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      color: $thead-font-color;
      font-family: "Poppins";
      text-align: left;
    }
    .ant-table-cell:first-child {
      color: $tbody-fontMachine-color;
      font-family: "Poppins";
      text-align: left;
      font-weight: 600;
    }
    .ant-table-cell:last-child {
      width: 140px;
      justify-content: center;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #1f1f1f;
  }
  .ant-form-item-control-input-content .ant-input {
    background-color: #1f1f1f;
  }

  .ant-col .ant-form-item-control {
    /* background-color: red; */
  }
  .ant-pagination-item {
    background-color: #222736;
    color: #c4c4c4;
  }

  .ant-pagination-item > a {
    /* background-color: #222736; */
    color: #c4c4c4;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: #222736;
    color: #c4c4c4;
  }

  .ant-table-pagination.ant-pagination {
    margin-right: 20px;
  }
}
