@import "./colors.scss";

.generalCheckbox {
    .ant-checkbox-wrapper {
        span {
          color:$text-color-des;
          font-size: 15px;
          font-family: 'Poppins';
        }
      }
      .ant-checkbox-inner {
        background-color: unset;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $checkbox-bg;
      }
      .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: 1px solid $checkbox-bg
      }
}