@import "../../pages/colors.scss";

.ant-badge {
  color: white !important;
}

.ant-badge-count {
  border: transparent;
  box-shadow: none;
}

.ant-layout-header {
  background-color: $bg-color;
  height: 80px;
  justify-content: center;
  align-items: center;
  line-height: 0px;
  right: 0px;
  padding: 0px 35px 0px 35px;
}

.bell-icon-container {
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: transparent !important;
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(73, 73, 73);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(41, 41, 41);
}

.notification-list-container {
  overflow-y: scroll;
  margin-right: 40px;
  background-color: $bg-color-secondary;
  width: 480px;
  border-radius: 5px;
  padding: 10px 0 10px 0;
  //border color
  box-shadow: 0 0 0 1.5px rgba(91, 91, 91, 0.5),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .mark-all {
    display: flex;
    flex-direction: row-reverse;
    height: 20px;
    margin: 10px 0 10px 0;
    padding: 0 10px 0 10px;

    .ant-btn-block {
      width: 110px;
      display: flex;
      flex-direction: row-reverse;
      font-size: 12px;
      font-family: "Poppins";
      font-weight: 500;
      color: #4d94ff;
      text-decoration: none;
      padding-right: 0;
    }

    .ant-btn-block:hover {
      color: rgb(68, 120, 242);
    }
  }
}

.notification-dropdown {
  overflow-y: scroll;
  max-height: 600px;
}
.header-container-IPS {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 0px 20px 0px 10px;
  margin: 0px;
  // position: fixed;
  z-index: 1;
  background-color: $bg-color;
  border-bottom: 1px solid $header-border-color;
}

.header-title {
  display: flex;
  flex: 1;
  align-items: center;
  .header-xep {
    justify-content: center;
    padding: 20px 20px 2px 20px;
  }
  .heading {
    font-size: 25px;
    font-family: "Poppins";
    font-weight: 800;
    color: $header-font-color;
    text-decoration: none;
  }
}
.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: unset;
  // right: 0px;
  left: 0px;
  .bell-icon-container {
    justify-content: center;
    align-items: center;
    a {
      color: #ffffff;
    }
    a:hover {
      color: $hover-color;
    }

    .ant-dropdown {
      margin-right: 20px !important;
    }
  }

  .setting-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    a {
      color: #ffffff;
    }
    a:hover {
      color: $hover-color;
    }
  }

  .user-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
      height: 80px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: transparent;
    }
  }
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}

.ant-row-rtl #components-dropdown-demo-placement .ant-btn {
  margin-right: 0;
  margin-bottom: 8px;
  margin-left: 8px;
}

.header-dropdown {
  top: 20px;
  right: 30px;
  background-color: $bg-color-secondary;
  width: 150px;
  border-radius: 5px;
  padding: 10px 0 10px 0;
  //border color
  box-shadow: 0 0 0 1.5px rgba(91, 91, 91, 0.5),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.notification-dropdown {
  top: 20px;
  right: 30px;
  background-color: $bg-color-secondary;
  width: auto;
  border-radius: 5px;
  padding: 10px 0 10px 0;
  //border color
  box-shadow: 0 0 0 1.5px rgba(91, 91, 91, 0.5),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .mark-all {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px 10px 10px 10px;
    padding: 0 10px 0 10px;
    color: $text-color;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 10px;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: $hover-dropdown-color;
  color: #ffffff;
}

.dropdown-Items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $text-color;
  line-height: 25px;
  font-family: "Poppins";
}

.ant-dropdown-menu-submenu-title {
  color: #383e51;
}

// @media (max-width: 700px) {
//   .header-search {
//     display: none;
//   }
//   .header-container {
//     width: 100%;
//   }
// }
// @media (max-width: 600px) {
//   .bell-icon {
//     display: none;
//   }
// }
// @media (max-width: 400px) {
//   .icon-container {
//     display: none;
//   }
// }

// @media (max-width: 992px) {
//   .header-xep {
//     margin-left: 35px;
//   }
// }

.ant-menu-item,
.ant-menu-submenu-title {
  padding-right: 10px;
  padding-left: 10px;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #ffffff;
  background-color: transparent;
}

.checkPasswordModal > button {
  width: 60px;
}
