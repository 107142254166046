@import "../colors.scss";

.main-container-login {
  background-color: $backgroundColor-login;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px;
    height: 450px;
    padding-bottom: 10px;
  }

  .login-form-header {
    height: 130px;
    background-color: $headerColor-login;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 10px 10px 10px 5px;
  }

  .form-footer {
    height: 380px;
    background-color: $footerColor-login;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .header-container-login {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .img-login {
    width: 120px;
    height: auto;
  }

  .detail-container {
    display: flex;
    flex-direction: column;
    width: 220px;
  }

  .h2-login {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    color: $headerFontColor-login;
    padding-left: 20px;
    padding-top: 10px;
    margin-bottom: 0;
  }

  .h4-login {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 23px;
    color: $headerFontColor-login;
    padding-left: 20px;
  }

  .form-footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-right: 35px;
    margin-left: 35px;
  }

  .ant-form-item-label > label {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: $labelColor-login !important;
  }

  .ant-input-affix-wrapper {
    background-color: $inputWrapperColor-login;
    border-width: 1px;
    border-color: $inputWrapperColor-login;
    border-radius: 4px;
    width: 270px;
    height: 40px;
  }

  .ant-input::placeholder {
    color: #d4d4d4;
    opacity: 0.25;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 100;
    font-size: 12px;
  }

  .form-footer-container .login-submit-btn {
    width: 100%;
    background-color: $headerColor-login;
    border: none;
    font-family: "Roboto";
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-input-password-icon {
    color: #d4d4d4;
    opacity: 0.25;
  }

  .ant-input-affix-wrapper > input.ant-input {
    color: #898e9e;
    background-color: #2e3548;
  }

  .checkbox-inner {
    .ant-checkbox-inner {
      background-color: #2e3548;
      border-color: #2e3548;
    }
  }
  .ant-checkbox-wrapper span {
    color: $labelColor-login;
    font-size: 13px;
    font-family: "Roboto";
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $headerColor-login;
  }

  .form-footer-container {
    .valuesCheck {
      flex: 1;
      flex-direction: row-reverse;
      .navigation {
        // margin-left: 25px;
        color: $labelColor-login;
        font-size: 13px;
        font-family: "Roboto";
      }
    }
    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      background-color: $inputWrapperColor-login;
      border-color: #ff4d4f;
    }
    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-input-focused,
    .ant-input:focus {
      border-color: $headerColor-login;
      box-shadow: none;
    }
    input[type="email"]:-webkit-autofill,
    input[type="email"]:-webkit-autofill:hover,
    input[type="email"]:-webkit-autofill:focus,
    input[type="email"]:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 50px $inputWrapperColor-login inset !important;
      -webkit-text-fill-color: #898e9e !important;
      -webkit-transition: "color 1000000s ease-out, background-color 1000000s ease-out";
      -webkit-transition-delay: 1000000s;
    }
    input[type="password"]:-webkit-autofill,
    input[type="password"]:-webkit-autofill:hover,
    input[type="password"]:-webkit-autofill:focus,
    input[type="password"]:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 50px $inputWrapperColor-login inset !important;
      -webkit-text-fill-color: #898e9e !important;
      -webkit-transition: "color 1000000s ease-out, background-color 1000000s ease-out";
      -webkit-transition-delay: 1000000s;
    }
  }

  .powered-by {
    margin-top: 5px;
    color: #898e9e;
  }

  .ant-btn.ant-btn-loading::before {
    background-color: $primary-color;
  }
}
