@import "../colors.scss";

.raw-subHeading-container {
  .raw-sub-text {
    color: $raw-title;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 400;
  }
}
