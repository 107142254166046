@import "../colors.scss";

.general-table-container {
  // width: 100%;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    // align-items: center;
    border-radius: 5px;
    // background-color: unset;
  }
  .ant-select-single:not(.ant-select-customize-input) {
    background-color: unset;
  }
  .ant-form-item-control-input-content .ant-input {
    height: 40px;
    // align-items: center;
    border-radius: 5px;
    border: 1.5px solid #444444;
  }
  // .ant-form-item-has-error {
  //   margin: 0px;
  //   align-items: flex-start;
  //   display: flex;
  //   // height: 100px;
  //   // background-color: red;
  // }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: $dropdown-color;
    font-family: "Poppins";
    font-weight: 300;
    line-height: 25px;
    font-size: 14px;
  }
  .ant-form-item-control-input-content {
    input {
      color: $dropdown-color;
      font-family: "Poppins";
      font-weight: 300;
      line-height: 25px;
      font-size: 14px;
      background-color: unset;
    }
    .ant-input::placeholder {
      color: $dropdown-color;
      font-family: "Poppins";
      font-weight: 300;
      line-height: 25px;
      font-size: 14px;
    }
  }
  input[type="values"]:-webkit-autofill,
  input[type="values"]:-webkit-autofill:hover,
  input[type="values"]:-webkit-autofill:focus,
  input[type="values"]:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px unset inset !important;
    -webkit-text-fill-color: #898e9e !important;
    -webkit-transition: "color 1000000s ease-out, background-color 1000000s ease-out";
    -webkit-transition-delay: 1000000s;
    color: $dropdown-color;
    font-family: "Poppins";
    font-weight: 300;
  }
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: unset;
  }
  .ant-form-item {
    display: flex;
    // align-items: center;
  }
}
