.quantitySuggestion {
  .ant-radio-button-wrapper {
    background-color: unset;
    // border-radius: 5px;
    // border: 1px solid #cacaca;
    color: #cacaca;
    font-family: "Poppins";
    border:none;
    background-color: #303030;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #073d74;
    // border: 1px solid #073d74;
  }
  .ant-radio-button-wrapper:not(:first-child)::before{
    background-color: unset !important;
  }
}
