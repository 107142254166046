@import "../colors.scss";

.search_filter_lables {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #cacaca !important;
  }
 
.machine-maintenance{
    a {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        color: #cacaca !important;
      }
    
      .ant-picker {
        background: #1b1b1b !important;
        height: 40px;
        border-radius: 5px;
        border: 1.5px solid #444444;
      }
    
      .ant-picker-input > input {
        color: #ffffff !important;
      }
    
      .search_menu_payment_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        margin-top: 2px;
        margin-left: 10px;
        margin-right: 15px;
        position: center;
      }
}  

.filter-section {
  display: block;
  margin-right: 100;
}

.chart-section{
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    display: block;
    align-content: center;
}

.edit_button{
  color: red;
}

.ant-IoExpandOutline {
  position: absolute;
  top: 5px;
}

.drawChartHeader {
  marginBottom: "40px"; 
  marginTop: "40px";
}

.drawChart {
  marginLeft: '40px'
}

.view-stepOne-header {
  border: 1px solid red;
 // padding: 50px 20px;
  border-radius: 10px;
  border-color: $card-border;
  margin-bottom: 20px;
  .ant-divider-inner-text {
    color: $step1-color;
    font-family: "Poppins";
    font-size: 20px;
  }
  .ant-btn-primary[disabled] {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: rgba(187, 187, 187, 0.79); // need to change
  }
  .form-row-header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    row-gap: 0px;
    justify-content: space-between;
  }
  .form-row-header-btn {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .ant-form-item-label > label {
    margin-right: 20px;
    justify-content: flex-start;
    display: flex;
  }
  // .status-tag {
  //   color: red;
  //   display: flex;
  //   flex: 1;
  //   width: 200px;
  //   justify-content: center;
  //   align-items: center;
  // }
}

.form-field-maintenance {
  // margin-top: 10px;
 // margin-bottom: 40px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 75px;
  // background-color: red;
  .ant-form-item {
    display: flex;
    align-items: center;
  }
  .form-button {
    height: 40px;
    border-radius: 5px;
    background-color: $selected-bg;
    color: $selected-color;
    font-family: "Poppins";
    border: none;
  }
  .ant-picker-clear {
    background-color: unset;
    color: $close-icon !important;
    background-color: $close-icon-bg;
  }
}

.statusColor {
  font-family: "Poppins";
  display: "inline-flex";
  flex-direction: "row";
  justify-content: "center";
  align-items: "center";
  margin-bottom: "5px";
  font-weight: 600;
}

.maintenance-title {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 100px;
  padding-bottom: 40px;
}

.card-overview {
  border: none;
  background-color: #242424;
  margin-left: 40px;
  border-radius: 12px;
}

.button-style {
  margin-top:20px;
  background-color: #1b1b1b;
  color:white;
  border: none; 
  border-radius: 5px;
}

.card-header {
  margin-bottom: 40px;
  margin-top: 50px;
   margin-left: -44px;
}

.button-style-zoom {
  margin-top:50px;
  background-color: #1b1b1b;
  color:white;
  border: none; 
  border-radius: 5px;
}