@import "./colors.scss";

.general-pagination {
  .ant-pagination .ant-pagination-item {
    background-color: unset;
    border-color: $pagination-border;
  }
  .ant-pagination .ant-pagination-item:hover {
    border-color: $primary-color;
    font-weight: 800;
  }
  .ant-pagination-item a {
    color: $progressText;
  }
  .ant-pagination .ant-pagination-item-active {
    border-color: $primary-color;
    font-weight: 800;
    a {
      color: $primary-color;
    }
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: 0.5px solid $pagination-border;
    color: $progressText;
    background-color: unset;
  }
  .ant-select-selector {
    color: $progressText;
    font-family: "Poppins";
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $pagination-border;
    align-items: center;
  }
  .ant-pagination-options-quick-jumper input {
    background-color: unset;
    border: 1px solid $pagination-border;
    color: $progressText;
    font-family: "Poppins";
  }
  .ant-pagination-options-quick-jumper {
    color: $progressText;
    font-family: "Poppins";
  }
  .ant-pagination-item-container {
    .ant-pagination-item-ellipsis {
      color: $progressText;
    }
    .ant-pagination-item-link-icon {
      color: $progressText;
    }
  }
}
