@import "../colors.scss";

.rep-search-container {
  // background-color: red;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin: unset;
  // background-color: #fff;
  .ant-select-single:not(.ant-select-customize-input) {
    background-color: unset;
  }
  .ant-picker-input input::placeholder {
    color: $search-font;
  }
  .ant-select-arrow {
    color: $search-font;
    height: 20px;
    width: 20px;
    margin-bottom: 10px;
  }

  .form-field-machine,
  .form-field-report {
    .ant-select-selector {
      height: 45px;
      border-radius: 6px;
    }
    .ant-select-selection-item {
      display: flex;
      flex: 1;
      align-items: center;
      font-family: "Poppins";
      color: $search-font;
    }
    .ant-form-item-label {
      display: flex;
      align-items: center;
    }
  }
  .form-field {
    margin: unset;
    .ant-picker {
      height: 40px;
      border: 1.5px solid $search-border;
      border-radius: 6px;
      background-color: $search-bg;
    }
    .ant-picker-input > input {
      font-family: "Poppins";
      color: $search-font;
    }
    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker {
      background-color: unset;
    }
    .ant-picker-suffix {
      color: $search-font;
    }
    .ant-picker-clear {
      background-color: unset;
      color: $close-icon !important;
      background-color: $close-icon-bg;
      height: 15px;
      width: 15px;
      justify-content: center;
      align-items: center;
      flex: 1;
      display: flex;
    }
  }
  .form-field-date {
    .ant-form-item-label {
      display: flex;
      align-items: center;
    }
  }
  .form-field-button {
    display: flex;
    // flex: 1;
    height: 90px;
    width: 100%;
    flex-direction: row;
 //   justify-content: flex-end;
  //  align-items: center;
    margin: unset;
    // margin-right: 25px;
    // background-color: red;
    // margin-top: 0px;
    button {
      height: 45px;
      background-color: $primary-color;
      border-radius: 6px;
      border: none;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      font-family: "Poppins";
      color: $search-font;
    }
    .ant-btn-primary {
      background-color: $primary-color;
      color: $button-font;
      font-family: "Poppins";
    }
  }
}
.ant-select-dropdown {
  background-color: $util-dropdown;
  border-radius: 5px;
  box-shadow: 0 0 0 1.5px rgba(91, 91, 91, 0.5),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  // color: #ffffff;
  // position: relative !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1.5px solid $hover-util-dropdown;
  background-color: unset;
}
// .ant-dropdown-menu-item:hover,

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $hover-util-dropdown;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $hover-util-dropdown;
}
// .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
//   // background-color: red;
// }

.ant-select-item-option-content {
  color: $dropdown-color;
  font-family: "Poppins";
  font-weight: 300;
  line-height: 25px;
}

.not-attached .ant-select-item-option-content{
  color: gray;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $selected-item;
}
.rc-virtual-list-scrollbar-thumb {
  background-color: $hover-util-dropdown !important;
}
.ant-picker-panel-container {
  background-color: $calender-bg;
  border-radius: 5px;
  margin-top: 2px;
  .ant-picker-panel {
    border: unset;
  }
  .ant-picker-footer {
    border-top: 1px solid $border-color;
    .ant-picker-today-btn {
      color: #073d74;
      font-size: 16px;
      font-family: "Poppins";
    }
  }
  .ant-picker-footer:hover {
    .ant-picker-today-btn {
      color: $calender-font-color;
    }
    background-color: #073d74;
  }
  .ant-picker-header {
    background-color: $calender-bg;
    color: $calender-font-color;
    font-family: "Poppins";
    border-bottom: unset;
  }
  .ant-picker-header > button {
    color: $calender-font-color;
  }
  .ant-picker-body {
    .ant-picker-content {
      thead {
        th {
          color: $calender-font-color;
          font-weight: 500;
        }
      }
      tbody {
        tr {
          .ant-picker-cell-in-view.ant-picker-cell-today {
            .ant-picker-cell-inner {
              background-color: $primary-color;
              color: $calender-font-color;
            }
          }

          .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
            .ant-picker-cell-inner,
          .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
            .ant-picker-cell-inner {
            background-color: $calender-hover;
          }
          .ant-picker-cell-inner {
            color: $calender-font-color;
          }
          .ant-picker-cell-disabled .ant-picker-cell-inner {
            color: $disabled-font;
          }
        }
      }
    }
  }
}
