@import "../colors.scss";

.bobbin-subHeading-container {
  .bobbin-sub-text {
    color: $bobbin-title;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 400;
  }
}
.ant-radio-wrapper {
  color: $bobbin-title;
  font-family: "Poppins";
}

.loading-show {
  display: none;
}

.loading-hide {
  display: block;
}

.ant-input::placeholder {
  color: $modal-label-color !important;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
