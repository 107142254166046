.ant-col.ant-col-10.ant-form-item-label {
  justify-content: flex-start;
  display: flex;
  flex: 1;
}

.ant-col .ant-col-15 .ant-form-item-control {
  justify-content: flex-start;
  display: flex;
  flex: 2;
}
.ant-form-horizontal .ant-form-item-control{
  max-width: 280px;
  margin-left: 30px;
}